import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { useEffect, useState } from "react";
import { Api } from "./proto";

export const firebase_app = firebase.initializeApp({
  apiKey: "AIzaSyBz9RY7g3Iev1LrdAXzZWqw3Uq2WAQIMlI",
  authDomain: "glow-prod.firebaseapp.com",
  databaseURL: "https://glow-prod.firebaseio.com",
  projectId: "glow-prod",
  storageBucket: "glow-prod.appspot.com",
  messagingSenderId: "138862362207",
  appId: "1:138862362207:web:d9c04e456234c26c71a5ea",
});

// TODO: pull base URL from a header or some other clever place
const API_BASE_URL = "https://glendon-mono-dot-glow-prod.uc.r.appspot.com";
// const API_BASE_URL = "http://localhost:7000";

/**
 * This hook returns whether the user is authenticated (signed in)
 * and whether they are authorized (allowed) to use the admin app.
 *
 * Side effect: on successful auth, it configures the Api to be that user.
 *
 * NOTE: these are `null` when we don't know yet
 *       i.e. when we're still waiting for firebase to resolve true/false
 * @returns {{
 *   is_signed_in: boolean|null,
 *   is_allowed: boolean|null,
 *   }}
 */
export function useAuth() {
  const [is_signed_in, set_is_signed_in] = useState(null); // null until true/false
  const [is_allowed, set_is_allowed] = useState(null); // null until true/false
  useEffect(() => {
    const stop = firebase_app.auth().onAuthStateChanged(async (user) => {
      set_is_signed_in(!!user);
      if (user) {
        try {
          let record = await firebase_app
            .database()
            .ref(`/users/${user.uid}`)
            .once("value");
          let auth_token =
            record.exists() && record.val().is_admin && record.val().auth_token;
          if (auth_token) {
            Api.configure({
              base_url: API_BASE_URL,
              auth_token,
            });
          }
          set_is_allowed(!!auth_token);
        } catch (err) {
          console.error("failure authorizing", user, err);
          set_is_allowed(false);
        }
      } else {
        set_is_allowed(null);
      }
    });
    return () => stop();
  }, []);
  return { is_signed_in, is_allowed };
}

export default firebase;
