import React from "react";
import ReactDOM from "react-dom";
import "bootswatch/dist/darkly/bootstrap.min.css";
import "./styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { IconContext } from "react-icons";
import moment from "moment";
import store from "./store";
import "./firebase";

// Setup short-form for relative times
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mth",
    MM: "%dmth",
    y: "1y",
    yy: "%dy",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IconContext.Provider value={{ style: { verticalAlign: "text-bottom" } }}>
        <App />
      </IconContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
