import protobufjs from "protobufjs";
import _ from "lodash";
import { useEffect, useReducer, useRef, useState } from "react";
import pb from "./gen/proto";

const proto_json = require("./gen/proto.json");
const _proto = protobufjs.Root.fromJSON(proto_json);
_proto.resolveAll();

const AdminApi = _proto.lookupService(
  "com.glowpush.glendon.admin.api.AdminApi"
);

let config = null; // set during configure

const _Api = Object.assign(
  {},
  {
    configure: (c) => (config = c),
  },
  ...AdminApi.methodsArray.map((method) => ({
    [_.camelCase(method.name)]: (request_data) => rpc(method, request_data),
  }))
);

const _UseApi = Object.assign(
  {},
  ...AdminApi.methodsArray.map((method) => {
    let api = _Api[_.camelCase(method.name)];
    return {
      [_.camelCase(method.name)]: (request_data, nonce) =>
        useApiMethod(api, request_data, nonce),
    };
  })
);

async function rpc(method, request_data) {
  if (config == null) {
    throw new Error(`missing API config`);
  }
  let path = `/admin/${method.name}`;
  let request_url = config.base_url + path;
  let req = method.resolvedRequestType.create(request_data);
  let body = method.resolvedRequestType.encode(req).finish(); // TODO: maybe wrap in buffer?
  let response = await fetch(request_url, {
    method: "POST",
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${config.auth_token}`,
    },
    body,
  });
  if (!response.ok) {
    throw new Error(`Api ${method.name} Failed: ${response.statusText}`);
  }
  let buffer = await response.arrayBuffer();
  let res = method.resolvedResponseType.decode(new Uint8Array(buffer));
  // console.log(method.name, req, res);
  return res;
}

// This is like "useEffect" but it deeply compares dependencies.
//   e.g. we can compare the entire `request` to decide if it has changed.
function useDeepEffect(callback, dependencies) {
  let prev = useRef();
  if (!_.isEqual(prev.current, dependencies)) {
    prev.current = dependencies;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, prev.current);
}

function useApiReducer(state, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        is_loading: true,
        is_failure: false,
        reload: action.reload,
      };
    case "FAILURE":
      return {
        ...state,
        is_loading: false,
        is_failure: true,
        response: null,
        reload: action.reload,
      };
    case "SUCCESS":
      return {
        ...state,
        is_loading: false,
        is_failure: false,
        response: action.response,
        reload: action.reload,
      };
    default:
      throw new Error(`unexpected action ${action.type}`);
  }
}

function useApiMethod(method, request, nonce) {
  let [state, dispatch] = useReducer(useApiReducer, {
    is_loading: true,
    is_failure: false,
    response: null,
    // until the effect begins, .reload is a no-op
    reload: () => {},
  });

  // We track requested_at to allow us to later
  // invalidate the previous request when we want to reload.
  let [requested_at, set_requested_at] = useState(Date.now());
  useDeepEffect(() => {
    let was_cancelled = false;
    let reload = () => {
      was_cancelled = true;
      set_requested_at(Date.now());
    };
    let execute = async () => {
      dispatch({ type: "LOADING", reload });
      try {
        let res = await method(request);
        if (!was_cancelled) {
          dispatch({ type: "SUCCESS", reload, response: res });
        }
      } catch (error) {
        if (!was_cancelled) {
          dispatch({ type: "FAILURE", reload });
        }
      }
    };
    execute();
    return () => {
      was_cancelled = true;
    };
  }, [
    request, // safe because of useDeepEffect
    requested_at, // so reload just requires setting a new requested_at
    nonce, // so a non-request param change can trigger reload
  ]);
  return state;
}

/**
 * @type {genApi}
 */
export const Api = _Api;

/**
 * @type {genUseApi}
 */
export const useApi = _UseApi;

export const topics = pb.com.glowpush.glendon.topics;

export const directive_names_by_value = _.invert(topics.Directive);
export const event_names_by_value = _.invert(topics.Event);
export const status_names_by_value = _.invert(topics.StatusCode);

export const proto = _proto;
