import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  Api,
  directive_names_by_value,
  event_names_by_value,
  proto,
  status_names_by_value,
  topics,
  useApi,
} from "./proto";
import moment from "moment";
import {
  MdAdd,
  MdClose,
  MdDelete,
  MdRefresh,
  MdRemove,
  MdSend,
} from "react-icons/md";
import { FaArrowLeft, FaArrowRight, FaRandom } from "react-icons/fa";
import random_color from "randomcolor";
import _ from "lodash";
import { IndexLinkContainer } from "react-router-bootstrap";
import { SketchPicker } from "react-color";
import { v4 as uuid } from "uuid";

// TODO: make this generic hook generally available
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Devices() {
  let q = useQuery();
  let [tag_texts, set_tag_texts] = useState(q.get("tag") ? [q.get("tag")] : []);
  let [is_trimming, set_trimming] = useState(false);
  let { is_loading, is_failure, response } = useApi.listDevices({
    page_size: 100, // TODO render paging UI
    tag_texts,
  });
  if (is_loading) {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }
  if (is_failure) {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <Alert variant="danger">Failed to load device list.</Alert>
          </Col>
        </Row>
      </Container>
    );
  }
  let { total_size, devices } = response;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h3>Devices ({total_size} total)</h3>
          <Button
            className="float-right"
            size="sm"
            disabled={is_trimming}
            onClick={() => {
              set_trimming(true);
              Api.trimArchives({
                since_hours_ago: 24, // TODO: make configurable
              }).finally(() => set_trimming(false));
            }}
          >
            {is_trimming ? (
              <span>Trimming...</span>
            ) : (
              <span>
                <MdDelete className="mr-1" />
                Trim Archives
              </span>
            )}
          </Button>
          <TagForm
            prompt="Filter by tag"
            className="mb-2"
            tagTexts={tag_texts}
            onAdd={(tag_text) =>
              set_tag_texts(_.uniq(tag_texts.concat([tag_text])))
            }
            onRemove={(tag_text) =>
              set_tag_texts(tag_texts.filter((t) => t !== tag_text))
            }
          />
          <ListGroup>
            {devices.map(({ tag_texts, device_id, hardware_identifier }) => (
              <IndexLinkContainer key={device_id} to={`/device/${device_id}`}>
                <ListGroupItem action>
                  {hardware_identifier}
                  <span className="ml-2">
                    {tag_texts.map((tag_text) => (
                      <Badge key={tag_text} className="mr-2" variant="info">
                        {tag_text}
                      </Badge>
                    ))}
                  </span>
                </ListGroupItem>
              </IndexLinkContainer>
            ))}
          </ListGroup>
          {/*<Button onClick={() => Api.createDevice({})}>*/}
          {/*  <MdAdd />*/}
          {/*</Button>*/}
        </Col>
      </Row>
    </Container>
  );
}

function TagForm({ className, tagTexts, onAdd, onRemove, prompt = "Add tag" }) {
  let { is_loading, is_failure, response } = useApi.listAllDeviceTags({
    page_size: 100,
  });
  let [filter, set_filter] = useState("");
  return (
    <Form
      className={className}
      inline
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (filter.length > 0) {
          onAdd && onAdd(filter);
        }
      }}
    >
      {(tagTexts || []).map((tag_text) => (
        <ButtonGroup key={tag_text} size="sm" className="mr-2 mb-2">
          <Button onClick={() => onRemove && onRemove(tag_text)}>
            <MdClose />
          </Button>
          <Button href={`/devices?tag=${tag_text}`}>{tag_text}</Button>
        </ButtonGroup>
      ))}
      <Dropdown className="mr-2 mb-2" onSelect={onAdd}>
        <Dropdown.Toggle size="sm" variant="secondary">
          <small>{prompt}</small>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>
            <InputGroup size="sm" className="w-auto">
              <Form.Control
                autoFocus
                placeholder=""
                onChange={(e) => set_filter(e.target.value)}
                value={filter}
              />
              <InputGroup.Append>
                <Button
                  size="sm"
                  onClick={() => onAdd && onAdd(filter)}
                  disabled={filter.length === 0}
                >
                  <MdAdd />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Dropdown.Header>
          {(is_loading || is_failure ? [] : response.tags)
            .filter(({ tag_text }) => tagTexts.indexOf(tag_text) === -1)
            .filter(
              ({ tag_text }) => !filter || tag_text.indexOf(filter) !== -1
            )
            .map(({ tag_text, device_count }) => (
              <Dropdown.Item key={tag_text} eventKey={tag_text}>
                {tag_text} <Badge variant="light">{device_count}</Badge>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form>
  );
}

export function Device() {
  let { device_id } = useParams();
  let [transcript_item, set_transcript_item] = useState({});
  let [pending, set_pending] = useState({ request_id: "" });
  return (
    <Container className="mt-5">
      <Row>
        <Col xs={3}>
          <DeviceSummary device_id={device_id} />
          <GroupSummary device_id={device_id} />
          <DirectiveForm
            onSend={({ directive, request }) =>
              Api.sendDirective({
                device_id,
                directive,
                request,
              })
                .then((res) => set_pending(res))
                .catch((err) => console.error(err))
            }
          />
        </Col>
        <Col xs={5}>
          <DeviceTranscript
            device_id={device_id}
            selected={transcript_item}
            onSelected={set_transcript_item}
            pending={pending}
          />
        </Col>
        <Col xs={4}>
          {transcript_item.event ? (
            <EventDetails event={transcript_item.event} />
          ) : transcript_item.directive_request ? (
            <DirectiveDetails directive={transcript_item.directive_request} />
          ) : transcript_item.directive_response ? (
            <DirectiveDetails directive={transcript_item.directive_response} />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

function DeviceSummary({ device_id }) {
  let { is_loading, is_failure, response, reload } = useApi.getDevice({
    device_id,
  });
  if (is_loading) {
    return (
      <Card body>
        <Spinner animation="border" />
      </Card>
    );
  }
  if (is_failure) {
    return (
      <Card border="danger" body>
        Failed to load device <code>{device_id}</code>
      </Card>
    );
  }
  // TODO: support alt-device identifier lookup:
  // TODO: if (response.device_id !== device_id) -> <Redirect to={`/device/${response.device_id}`)/>
  if (!response.device) {
    return (
      <Card border="danger" body>
        Unable to find device <code>{device_id}</code>
      </Card>
    );
  }
  let { hardware_identifier, tag_texts, created_at } = response.device;
  return (
    <Card>
      <Card.Body>
        <Card.Title>{hardware_identifier}</Card.Title>
        <TagForm
          tagTexts={tag_texts}
          onAdd={(tag_text) =>
            Api.addDeviceTag({
              device_id,
              tag_text,
            }).then(reload)
          }
          onRemove={(tag_text) =>
            Api.removeDeviceTag({
              device_id,
              tag_text,
            }).then(reload)
          }
        />
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">
          Created {moment(created_at).fromNow()}
        </small>
      </Card.Footer>
    </Card>
  );
}

function GroupSummary({ device_id }) {
  let { is_loading, is_failure, response, reload } = useApi.listGroups({
    device_id,
    page_size: 100, // TODO render paging UI
  });
  if (is_loading) {
    return (
      <Card body>
        <Spinner animation="border" />
      </Card>
    );
  }
  if (is_failure) {
    return (
      <Card border="danger" body>
        Failed to load groups
      </Card>
    );
  }
  let { groups } = response;
  let existing_group_ids = groups.map(({ group_id }) => group_id);
  return (
    <Card className="mt-5">
      <Card.Body>
        <Card.Title>Groups</Card.Title>
        {groups.map(({ group_id, group_name }) => (
          <ButtonGroup size="sm" className="mr-2 mb-2">
            <Button
              onClick={() =>
                Api.removeGroupDevice({ group_id, device_id }).then(reload)
              }
            >
              <MdClose />
            </Button>
            <Button href={`/group/${group_id}`}>{group_name}</Button>
          </ButtonGroup>
        ))}
        <AddToGroup
          existing_group_ids={existing_group_ids}
          onAdd={(group_id) =>
            Api.addGroupDevice({
              group_id,
              device_id,
              group_device_color: 0xffffff, // default upon creation
            }).then(reload)
          }
        />
      </Card.Body>
    </Card>
  );
}

function AddToGroup({ existing_group_ids, onAdd }) {
  let { is_loading, is_failure, response } = useApi.listGroups({
    page_size: 100, // TODO render paging UI
  });
  return (
    <Dropdown className="mr-2 mb-2" onSelect={onAdd}>
      <Dropdown.Toggle size="sm" variant="secondary">
        <small>Add to group</small>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {(is_loading || is_failure ? [] : response.groups)
          .filter(({ group_id }) => existing_group_ids.indexOf(group_id) === -1)
          .map(({ group_id, group_name, devices }) => (
            <Dropdown.Item key={group_id} eventKey={group_id}>
              {group_name} <Badge variant="light">{devices.length}</Badge>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DirectiveForm({ onSend }) {
  let [directive, set_directive] = useState(
    topics.Directive.DIRECTIVE_UNSPECIFIED
  );
  let [filter, set_filter] = useState("");
  let [req, set_req] = useState({ is_ready: true, args: {} });

  let req_name, req_type;
  if (directive) {
    req_name =
      _.upperFirst(_.camelCase(directive_names_by_value[directive])) +
      "Request";
    req_type = proto.lookupType(req_name);
  }
  return (
    <Card className="mt-5">
      <Card.Body>
        <Dropdown onSelect={(value) => set_directive(value)}>
          <Dropdown.Toggle variant={directive ? "primary" : "secondary"}>
            {!directive ? (
              <small>Pick a directive to send</small>
            ) : (
              _.startCase(_.camelCase(directive_names_by_value[directive]))
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>
              <Form.Control
                autoFocus
                className="w-auto"
                placeholder=""
                onChange={(e) => set_filter(e.target.value)}
                value={filter}
              />
            </Dropdown.Header>
            {_.orderBy(
              Object.entries(topics.Directive).filter(
                ([name, value]) =>
                  value &&
                  (!filter || name.toLowerCase().includes(filter.toLowerCase()))
              ), // exclude unspecified (value = 0)
              ([name, value]) => name
            ).map(([name, value]) => (
              <Dropdown.Item
                key={value}
                eventKey={value}
                active={directive === value}
              >
                {_.startCase(_.camelCase(name))}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {!req_type ? null : (
          <DirectiveRequestForm
            type={req_type}
            args={req.args}
            onChange={(req) => set_req(req)}
          />
        )}
      </Card.Body>
      {!directive ? null : (
        <Card.Footer>
          <Button
            onClick={() =>
              set_directive(topics.Directive.DIRECTIVE_UNSPECIFIED)
            }
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            className="float-right"
            disabled={!req.is_ready}
            onClick={() =>
              onSend({
                directive,
                request: req_type.encode(req.args).finish(),
              })
            }
            variant="primary"
          >
            Send <MdSend />
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
}

function DirectiveRequestForm({ type, args, onChange }) {
  return (
    <Form className="mt-3">
      {type.fieldsArray.map((field) => (
        <DirectiveRequestFormField
          field={field}
          key={field.name}
          value={args[field.name]}
          onChange={(v) =>
            onChange({
              is_ready: true,
              args: { ...args, [field.name]: v },
            })
          }
        />
      ))}
    </Form>
  );
}

function DirectiveRequestFormField({
  field,
  value,
  onChange,
  prefix = "",
  asEntryNumber = 0,
}) {
  if (field.repeated && !asEntryNumber) {
    return (
      <DirectiveRequestRepeatedFormField
        field={field}
        value={value}
        onChange={onChange}
        prefix={prefix}
      />
    );
  }
  let entry_number = asEntryNumber || 0;
  let label = entry_number
    ? `#${entry_number}`
    : _.startCase(_.camelCase(field.name));
  if (field.type === "bool") {
    return (
      <Form.Check
        type="checkbox"
        className="mb-3"
        size="sm"
        id={prefix + field.name}
        label={label}
        checked={!!value}
        onChange={(event) => onChange(event.target.checked)}
      />
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Enum"
  ) {
    // give pithy names to enums by stripping the common prefix
    //  e.g. "OPERATING_MODE_STATION" -> "Station"
    let prefix = common_prefix(Object.keys(field.resolvedType.values));
    return (
      <Form.Group controlId={prefix + field.name} size="sm">
        <Form.Label size="sm">{label}</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          value={value || 0}
          onChange={(event) => onChange(event.target.value)}
        >
          {Object.entries(field.resolvedType.values).map(
            ([value_name, value_number]) => (
              <option key={value_number} value={value_number}>
                {/* simplify names: "OPERATING_MODE_STATION" -> "Station" */}
                {_.startCase(_.camelCase(value_name.substring(prefix.length)))}
              </option>
            )
          )}
        </Form.Control>
      </Form.Group>
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Type" &&
    field.type === "Color"
  ) {
    return (
      <Form.Group controlId={prefix + field.name} size="sm">
        <Form.Label>{label}</Form.Label>
        <ColorPicker color={value || {}} onChange={onChange} />
      </Form.Group>
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Type"
  ) {
    return (
      <fieldset>
        <Form.Label>{label}</Form.Label>
        {field.resolvedType.fieldsArray.map((sub_field) => (
          <DirectiveRequestFormField
            key={sub_field.name}
            prefix={`${prefix}${field.name}_${entry_number}_`}
            field={sub_field}
            value={value && value[sub_field.name]}
            onChange={(v) =>
              onChange({ ...(value || {}), [sub_field.name]: v })
            }
          />
        ))}
      </fieldset>
    );
  }
  if (field.type === "int64" && field.name.endsWith("_at")) {
    return (
      <Form.Group
        className="clearfix"
        controlId={prefix + field.name}
        size="sm"
      >
        <Form.Label>{label}</Form.Label>
        <InputGroup size="sm" className="w-auto">
          <Form.Control
            onChange={(event) => onChange(event.target.value)}
            value={value || 0}
            type="text"
            placeholder={field.name}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              <TimeAgo at={value || 0} />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <ButtonGroup className="float-right">
          <Button size="sm" onClick={() => onChange(Date.now())}>
            Now
          </Button>
          <Button
            size="sm"
            onClick={() => onChange((value || Date.now) - 60 * 1000)}
          >
            -1m
          </Button>
          <Button
            size="sm"
            onClick={() => onChange((value || Date.now) - 60 * 60 * 1000)}
          >
            -1h
          </Button>
        </ButtonGroup>
      </Form.Group>
    );
  }
  if (field.type === "string" && field.name.endsWith("_id")) {
    return (
      <Form.Group controlId={prefix + field.name} size="sm">
        <Form.Label>{label}</Form.Label>
        <InputGroup size="sm" className="w-auto">
          <Form.Control
            onChange={(event) => onChange(event.target.value)}
            value={value || ""}
            type="text"
            placeholder={field.name}
          />
          <InputGroup.Append>
            <Button onClick={() => onChange(uuid())}>
              <FaRandom />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    );
  }
  return (
    <Form.Group controlId={prefix + field.name} size="sm">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        onChange={(event) => onChange(event.target.value)}
        value={value || ""}
        type="text"
        placeholder={field.name}
      />
    </Form.Group>
  );
}

function DirectiveRequestRepeatedFormField({
  field,
  value,
  onChange,
  prefix = "",
}) {
  value = (value || []).slice();
  let max_count = field.options["(nanopb).max_count"] || 0;
  return (
    <div className="mt-3">
      <Form.Label>
        {_.startCase(_.camelCase(field.name))} ({value.length}/
        {max_count || "unlimited"})
      </Form.Label>
      <div className="mb-2">
        <ButtonGroup size="sm">
          <Button
            key="remove"
            disabled={value.length < 1}
            onClick={() => onChange(value.slice(0, value.length - 1))}
          >
            <MdRemove />
          </Button>
          <Button
            key="add"
            disabled={max_count && value.length >= max_count}
            onClick={() => onChange(value.concat([{}]))}
          >
            <MdAdd />
          </Button>
        </ButtonGroup>
      </div>
      {value.map((v, i) => (
        <div
          key={i}
          className="mt-3"
          style={
            i % 2
              ? {
                  // put a thin line alongside alternating entries
                  marginLeft: -10,
                  paddingLeft: 10,
                  borderLeft: "1px groove #aaa",
                }
              : {}
          }
        >
          <DirectiveRequestFormField
            prefix={prefix}
            asEntryNumber={i + 1}
            onChange={(updated) => {
              value[i] = updated;
              onChange(value);
            }}
            {...{ field, value: v }}
          />
        </div>
      ))}
    </div>
  );
}

function TimeAgo({ at, interval = 10000 }) {
  let [text, set_text] = useState(moment(at).fromNow());
  useEffect(() => {
    set_text(moment(at).fromNow());
    const timer = setInterval(() => set_text(moment(at).fromNow()), interval);
    return () => clearInterval(timer);
  }, [at, interval]);
  return text;
}

function ColorPicker({ color, onChange, presets }) {
  let [picking, set_picking] = useState(false);
  return (
    <div className="mt-0 mb-0">
      <ButtonGroup size="sm">
        <Button variant="secondary" onClick={() => set_picking(!picking)}>
          <div
            style={{
              width: 36,
              height: 14,
              background: `rgba(${color.red}, ${color.green}, ${color.blue}, 1)`,
            }}
          />
        </Button>
        <Button
          onClick={() => {
            let [red, green, blue] = random_color({
              luminosity: "light",
              format: "rgbArray",
            });
            onChange({ red, green, blue });
          }}
          variant="secondary"
        >
          <FaRandom />
        </Button>
      </ButtonGroup>
      {!picking ? null : (
        <SketchPicker
          className="mt-2"
          disableAlpha
          width={193}
          onChange={({ rgb: { r, g, b } }) =>
            onChange({
              red: r,
              green: g,
              blue: b,
            })
          }
          color={{
            r: color.red || 0,
            g: color.green || 0,
            b: color.blue || 0,
          }}
          presetColors={["#f00", "#ff0", "#0f0", "#0ff", "#00f", "#f0f"]}
        />
      )}
    </div>
  );
}

function DeviceTranscript({ device_id, selected, onSelected, pending }) {
  let [include_statuses, set_include_statuses] = useState(false);
  let [is_refreshing, set_is_refreshing] = useState(false);
  let ds = useApi.listDeviceDirectives({ device_id, page_size: 10 }, pending);
  let es = useApi.listDeviceEvents({ device_id, page_size: 10 }, pending);
  let [auto, set_auto] = useAuto(
    () => {
      ds.reload();
      es.reload();
    },
    [ds, es],
    true,
    5_000
  );

  if (ds.is_failure || es.is_failure) {
    return (
      <Card border="danger" body>
        Failed to load device transcript
      </Card>
    );
  }
  if (!ds.response || !es.response) {
    return (
      <Card body>
        <Spinner animation="border" />
      </Card>
    );
  }
  let items = _.orderBy(
    []
      .concat(
        es.response.events
          .filter(
            (event) =>
              include_statuses || event.event !== topics.Event.STATUS_UPDATED
          )
          .map((event) => ({
            event,
            id: event.event_id,
            at: event.created_at,
          }))
      )
      .concat(
        _.flatten(
          ds.response.directives.map((directive) =>
            [
              {
                directive_request: directive,
                id: directive.request_id,
                at: directive.created_at,
              },
            ].concat(
              !directive.directive_res_payload
                ? []
                : [
                    {
                      directive_response: directive,
                      id: directive.request_id + "-res",
                      at: directive.updated_at,
                    },
                  ]
            )
          )
        )
      ),
    "at",
    "desc"
  );
  selected = selected || {};
  return (
    <Card>
      <ListGroup variant="flush">
        <ListGroup.Item key="filter_form" className="border-bottom border-info">
          <Form inline className="float-left">
            <Form.Check
              type="checkbox"
              size="sm"
              id="include_statuses"
              label={<small>Status Events</small>}
              checked={include_statuses}
              onChange={(event) => set_include_statuses(event.target.checked)}
            />
          </Form>
          <Form inline className="float-right">
            <Col>
              <Button
                className="mb-2"
                disabled={auto || is_refreshing}
                onClick={() => {
                  ds.reload();
                  es.reload();
                  set_is_refreshing(true);
                  setTimeout(() => set_is_refreshing(false), 1000);
                }}
              >
                {auto || is_refreshing ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  <MdRefresh size={16} />
                )}
              </Button>
              <Form.Check
                type="checkbox"
                size="sm"
                id="auto_refresh"
                label={<small>Auto</small>}
                checked={auto}
                onChange={(event) => set_auto(event.target.checked)}
              />
            </Col>
          </Form>
        </ListGroup.Item>
        {items.length ? null : (
          <ListGroup.Item>No items match the current filters.</ListGroup.Item>
        )}
        {items.map((item, i) => (
          <ListGroup.Item
            key={item.id}
            action
            active={item.id === selected.id}
            onClick={() => onSelected(item)}
          >
            {item.event ? (
              <EventListItem event={item.event} />
            ) : item.directive_request ? (
              <DirectiveRequestListItem directive={item.directive_request} />
            ) : item.directive_response ? (
              <DirectiveResponseListItem directive={item.directive_response} />
            ) : null}
          </ListGroup.Item>
        ))}
        {/* TODO: allow paging / loading more */}
      </ListGroup>
    </Card>
  );
}

function EventListItem({ event }) {
  let { created_at } = event;
  let color = "#555";
  let is_status = event.event === topics.Event.STATUS_UPDATED;
  return (
    <Container>
      <Row>
        <Col xs={3} className="p-0">
          <small className="text-muted">{moment(created_at).fromNow()}</small>
        </Col>
        <Col>
          <span className={is_status ? "text-muted" : ""}>
            {_.startCase(_.camelCase(event_names_by_value[event.event]))}
          </span>{" "}
          <FaArrowRight color={color} />
        </Col>
      </Row>
    </Container>
  );
}

function DirectiveResponseListItem({ directive }) {
  let { request_id, created_at, updated_at, directive_res_payload } = directive;
  let color = random_color({ luminosity: "light", seed: request_id });
  return (
    <Container>
      <Row>
        <Col xs={3} className="p-0">
          <small className="text-muted">{moment(created_at).fromNow()}</small>
        </Col>
        <Col>
          {_.startCase(
            _.camelCase(directive_names_by_value[directive.directive])
          )}{" "}
          <small style={{ color }}>
            {_.startCase(
              _.camelCase(
                status_names_by_value[directive_res_payload.status_code]
              )
            )}{" "}
            {updated_at - created_at}ms
          </small>{" "}
          <FaArrowRight color={color} />
        </Col>
      </Row>
    </Container>
  );
}

function DirectiveRequestListItem({ directive }) {
  let { request_id, created_at } = directive;
  let color = random_color({ luminosity: "light", seed: request_id });
  return (
    <Container>
      <Row>
        <Col xs={3} className="p-0">
          <small className="text-muted">{moment(created_at).fromNow()}</small>
        </Col>
        <Col style={{ marginLeft: "-18px" }}>
          <FaArrowLeft color={color} />{" "}
          {_.startCase(
            _.camelCase(directive_names_by_value[directive.directive])
          )}{" "}
        </Col>
      </Row>
    </Container>
  );
}

function EventDetails({ event }) {
  let { created_at } = event;
  let [show_source, set_show_source] = useState(false);
  let name = _.upperFirst(_.camelCase(event_names_by_value[event.event]));
  let event_type = proto.lookupType(name);
  let info = event_type.decode(new Uint8Array(event.event_payload.info));
  return (
    <Card>
      <Card.Body>
        <Card.Title>{_.startCase(name)}</Card.Title>
        <Card.Text className="text-muted">
          {moment(created_at).fromNow()}
        </Card.Text>
      </Card.Body>
      <ListGroup>
        {event_type.fieldsArray.map((field) => (
          <ProtoField field={field} value={info[field.name]} has_value={true} />
        ))}
      </ListGroup>
      <Card.Footer>
        <Button
          variant="secondary"
          size="sm"
          className="float-right"
          onClick={() => set_show_source(!show_source)}
        >
          Source
        </Button>
        {!show_source ? null : (
          <div style={{ clear: "both" }}>
            <pre>{JSON.stringify(event, null, 2)}</pre>
          </div>
        )}
      </Card.Footer>
    </Card>
  );
}

function DirectiveDetails({ directive }) {
  let { response } = useApi.getDirective({
    request_id: directive.request_id,
  });
  let [show_source, set_show_source] = useState(false);
  let [show_request, set_show_request] = useState(true);
  let [show_response, set_show_response] = useState(true);

  if (response) {
    directive = response.directive;
  }
  let {
    directive_req_payload,
    directive_res_payload,
    created_at,
    updated_at,
  } = directive;
  let name = _.upperFirst(
    _.camelCase(directive_names_by_value[directive.directive])
  );
  let req_type = proto.lookupType(name + "Request");
  let res_type = proto.lookupType(name + "Response");
  let req =
    directive_req_payload &&
    req_type.decode(new Uint8Array(directive_req_payload.request));
  let res =
    directive_res_payload &&
    res_type.decode(new Uint8Array(directive_res_payload.response));
  let status_code = directive_res_payload && directive_res_payload.status_code;
  return (
    <Card>
      <Card.Body>
        <Card.Title>{_.startCase(name)}</Card.Title>
        <Card.Text className="text-muted">
          {moment(created_at).fromNow()}
          {directive_res_payload ? ` • ${updated_at - created_at}ms` : null}
        </Card.Text>
      </Card.Body>
      <ListGroup className="mb-2">
        <ListGroup.Item
          action
          className={show_request ? "text-muted" : ""}
          onClick={() => set_show_request(!show_request)}
        >
          Request
        </ListGroup.Item>
        {!show_request ? null : !req_type.fieldsArray.length ? (
          <ListGroup.Item>
            <Container>
              <Row>
                <Col style={{ textAlign: "center" }}>
                  <em className="text-muted">No fields</em>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
        ) : (
          req_type.fieldsArray.map((field) => (
            <ProtoField
              field={field}
              key={field.name}
              value={req && req[field.name]}
              has_value={!!req}
            />
          ))
        )}
      </ListGroup>
      <ListGroup className="mb-2">
        <ListGroup.Item
          action
          className={show_request ? "text-muted" : ""}
          onClick={() => set_show_response(!show_response)}
        >
          Response{" "}
          <Badge variant={res ? (status_code ? "danger" : "success") : "info"}>
            {res ? (
              _.startCase(_.camelCase(status_names_by_value[status_code]))
            ) : (
              <em>pending</em>
            )}
          </Badge>
        </ListGroup.Item>
        {!show_response ? null : !res_type.fieldsArray.length ? (
          <ListGroup.Item>
            <Container>
              <Row>
                <Col style={{ textAlign: "center" }}>
                  <em className="text-muted">No fields</em>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
        ) : (
          res_type.fieldsArray.map((field) => (
            <ProtoField
              key={field.name}
              field={field}
              value={res && res[field.name]}
              has_value={!!res}
            />
          ))
        )}
      </ListGroup>
      <Card.Footer>
        <Button
          variant="secondary"
          size="sm"
          className="float-right"
          onClick={() => set_show_source(!show_source)}
        >
          Source
        </Button>
        {!show_source ? null : (
          <div style={{ clear: "both" }}>
            <pre>{JSON.stringify(directive, null, 2)}</pre>
            <pre>
              req:{" "}
              {req &&
                JSON.stringify(
                  req_type.toObject(req, { defaults: true }),
                  null,
                  2
                )}
            </pre>
            <pre>
              res:{" "}
              {res &&
                JSON.stringify(
                  res_type.toObject(res, { defaults: true }),
                  null,
                  2
                )}
            </pre>
          </div>
        )}
      </Card.Footer>
    </Card>
  );
}

function ProtoField({ field, prefix, value, has_value, asEntryNumber = 0 }) {
  if (field.repeated && !asEntryNumber) {
    return (
      <div>
        {(value || []).map((v, i) => (
          <ProtoField
            key={i}
            asEntryNumber={i + 1}
            value={v}
            field={field}
            prefix={`${prefix}[${i}]`}
            has_value={has_value}
          />
        ))}
      </div>
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Type"
  ) {
    return (
      <>
        <ListGroup.Item
          key={`${field.name}_${asEntryNumber}`}
          className="pl-0 pr-0 pb-0"
        >
          <Container>
            <Row>
              <Col
                xs={6}
                style={{ textAlign: "right" }}
                className="pl-0 pr-2 border-left border-secondary"
              >
                <small>
                  <code>
                    {prefix || ""}
                    {field.name}
                  </code>
                </small>
              </Col>
            </Row>
          </Container>
        </ListGroup.Item>
        {field.resolvedType.fieldsArray.map((sub_field) => (
          <ProtoField
            field={sub_field}
            key={sub_field.name}
            prefix={`${prefix || ""}.`}
            has_value={has_value}
            value={has_value && value && value[sub_field.name]}
          />
        ))}
      </>
    );
  }
  return (
    <ListGroup.Item key={field.name} className="pl-0 pr-0">
      <Container>
        <Row>
          <Col
            xs={6}
            style={{ textAlign: "right" }}
            className="pl-0 pr-2 border-left border-secondary"
          >
            <small>
              <code>
                {prefix || ""}
                {field.name}
              </code>
            </small>
          </Col>
          <Col xs={6} className="pl-2 pr-0">
            {!has_value ? (
              <em>pending</em>
            ) : (
              <ProtoFieldValue field={field} value={value} />
            )}
          </Col>
        </Row>
      </Container>
    </ListGroup.Item>
  );
}

function ProtoFieldValue({ field, value }) {
  if (field.type === "int64" && field.name.endsWith("_at")) {
    return (
      <div>
        <p>{moment(value).fromNow()}</p>
        <small>{moment(value).toISOString()}</small>
      </div>
    );
  }
  if (
    field.resolvedType &&
    field.resolvedType.constructor.className === "Enum"
  ) {
    // give pithy names to enums by stripping the common prefix
    //  e.g. "OPERATING_MODE_STATION" -> "Station"
    let prefix = common_prefix(Object.keys(field.resolvedType.values));
    let value_name = field.resolvedType.valuesById[value];
    return _.startCase(_.camelCase(value_name.substring(prefix.length)));
  }
  return JSON.stringify(value);
}

// Generic Helpers

function common_prefix(names) {
  let sorted = names.concat().sort();
  let first = sorted[0];
  let last = sorted[sorted.length - 1];
  let x = _.takeWhile(first, (c, i) => last.charAt(i) === c);
  return x.join("");
}

function useAuto(fn, deps, initial_auto = true, delay_ms = 3_000) {
  let [auto, set_auto] = useState(initial_auto);
  useEffect(() => {
    let auto_refresh;
    let terminated = false;
    function refresh() {
      if (!terminated && auto) {
        // don't run `fn()` if we're in the background
        if (document.visibilityState !== "hidden") {
          fn();
        }
        auto_refresh = setTimeout(refresh, delay_ms);
      } else {
        clearTimeout(auto_refresh);
        auto_refresh = false;
      }
    }
    if (auto) {
      auto_refresh = setTimeout(refresh, delay_ms);
    }
    return () => {
      terminated = true;
      clearTimeout(auto_refresh);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auto, delay_ms, fn].concat(deps));
  return [auto, set_auto];
}
