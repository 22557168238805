import { createSlice } from "@reduxjs/toolkit";

// TODO: decide if we need this slice

export const apiSlice = createSlice({
  name: "api",
  initialState: {},
  reducers: {},
});

// export const {} = apiSlice.actions;

export default apiSlice.reducer;
