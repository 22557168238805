/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const com = $root.com = (() => {

    /**
     * Namespace com.
     * @exports com
     * @namespace
     */
    const com = {};

    com.glowpush = (function() {

        /**
         * Namespace glowpush.
         * @memberof com
         * @namespace
         */
        const glowpush = {};

        glowpush.glendon = (function() {

            /**
             * Namespace glendon.
             * @memberof com.glowpush
             * @namespace
             */
            const glendon = {};

            glendon.admin = (function() {

                /**
                 * Namespace admin.
                 * @memberof com.glowpush.glendon
                 * @namespace
                 */
                const admin = {};

                admin.api = (function() {

                    /**
                     * Namespace api.
                     * @memberof com.glowpush.glendon.admin
                     * @namespace
                     */
                    const api = {};

                    api.AdminApi = (function() {

                        /**
                         * Constructs a new AdminApi service.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents an AdminApi
                         * @extends $protobuf.rpc.Service
                         * @constructor
                         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                         */
                        function AdminApi(rpcImpl, requestDelimited, responseDelimited) {
                            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                        }

                        (AdminApi.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AdminApi;

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#addDeviceTag}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef AddDeviceTagCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.AddDeviceTagResponse} [response] AddDeviceTagResponse
                         */

                        /**
                         * Calls AddDeviceTag.
                         * @function addDeviceTag
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IAddDeviceTagRequest} request AddDeviceTagRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.AddDeviceTagCallback} callback Node-style callback called with the error, if any, and AddDeviceTagResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.addDeviceTag = function addDeviceTag(request, callback) {
                            return this.rpcCall(addDeviceTag, $root.com.glowpush.glendon.admin.api.AddDeviceTagRequest, $root.com.glowpush.glendon.admin.api.AddDeviceTagResponse, request, callback);
                        }, "name", { value: "AddDeviceTag" });

                        /**
                         * Calls AddDeviceTag.
                         * @function addDeviceTag
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IAddDeviceTagRequest} request AddDeviceTagRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.AddDeviceTagResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#removeDeviceTag}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef RemoveDeviceTagCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.RemoveDeviceTagResponse} [response] RemoveDeviceTagResponse
                         */

                        /**
                         * Calls RemoveDeviceTag.
                         * @function removeDeviceTag
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IRemoveDeviceTagRequest} request RemoveDeviceTagRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.RemoveDeviceTagCallback} callback Node-style callback called with the error, if any, and RemoveDeviceTagResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.removeDeviceTag = function removeDeviceTag(request, callback) {
                            return this.rpcCall(removeDeviceTag, $root.com.glowpush.glendon.admin.api.RemoveDeviceTagRequest, $root.com.glowpush.glendon.admin.api.RemoveDeviceTagResponse, request, callback);
                        }, "name", { value: "RemoveDeviceTag" });

                        /**
                         * Calls RemoveDeviceTag.
                         * @function removeDeviceTag
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IRemoveDeviceTagRequest} request RemoveDeviceTagRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.RemoveDeviceTagResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#listAllDeviceTags}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef ListAllDeviceTagsCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse} [response] ListAllDeviceTagsResponse
                         */

                        /**
                         * Calls ListAllDeviceTags.
                         * @function listAllDeviceTags
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListAllDeviceTagsRequest} request ListAllDeviceTagsRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.ListAllDeviceTagsCallback} callback Node-style callback called with the error, if any, and ListAllDeviceTagsResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.listAllDeviceTags = function listAllDeviceTags(request, callback) {
                            return this.rpcCall(listAllDeviceTags, $root.com.glowpush.glendon.admin.api.ListAllDeviceTagsRequest, $root.com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse, request, callback);
                        }, "name", { value: "ListAllDeviceTags" });

                        /**
                         * Calls ListAllDeviceTags.
                         * @function listAllDeviceTags
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListAllDeviceTagsRequest} request ListAllDeviceTagsRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#createDevice}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef CreateDeviceCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.CreateDeviceResponse} [response] CreateDeviceResponse
                         */

                        /**
                         * Calls CreateDevice.
                         * @function createDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ICreateDeviceRequest} request CreateDeviceRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.CreateDeviceCallback} callback Node-style callback called with the error, if any, and CreateDeviceResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.createDevice = function createDevice(request, callback) {
                            return this.rpcCall(createDevice, $root.com.glowpush.glendon.admin.api.CreateDeviceRequest, $root.com.glowpush.glendon.admin.api.CreateDeviceResponse, request, callback);
                        }, "name", { value: "CreateDevice" });

                        /**
                         * Calls CreateDevice.
                         * @function createDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ICreateDeviceRequest} request CreateDeviceRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.CreateDeviceResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#getDevice}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef GetDeviceCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.GetDeviceResponse} [response] GetDeviceResponse
                         */

                        /**
                         * Calls GetDevice.
                         * @function getDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IGetDeviceRequest} request GetDeviceRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.GetDeviceCallback} callback Node-style callback called with the error, if any, and GetDeviceResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.getDevice = function getDevice(request, callback) {
                            return this.rpcCall(getDevice, $root.com.glowpush.glendon.admin.api.GetDeviceRequest, $root.com.glowpush.glendon.admin.api.GetDeviceResponse, request, callback);
                        }, "name", { value: "GetDevice" });

                        /**
                         * Calls GetDevice.
                         * @function getDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IGetDeviceRequest} request GetDeviceRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.GetDeviceResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#getDirective}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef GetDirectiveCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.GetDirectiveResponse} [response] GetDirectiveResponse
                         */

                        /**
                         * Calls GetDirective.
                         * @function getDirective
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IGetDirectiveRequest} request GetDirectiveRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.GetDirectiveCallback} callback Node-style callback called with the error, if any, and GetDirectiveResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.getDirective = function getDirective(request, callback) {
                            return this.rpcCall(getDirective, $root.com.glowpush.glendon.admin.api.GetDirectiveRequest, $root.com.glowpush.glendon.admin.api.GetDirectiveResponse, request, callback);
                        }, "name", { value: "GetDirective" });

                        /**
                         * Calls GetDirective.
                         * @function getDirective
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IGetDirectiveRequest} request GetDirectiveRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.GetDirectiveResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#listDevices}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef ListDevicesCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.ListDevicesResponse} [response] ListDevicesResponse
                         */

                        /**
                         * Calls ListDevices.
                         * @function listDevices
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListDevicesRequest} request ListDevicesRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.ListDevicesCallback} callback Node-style callback called with the error, if any, and ListDevicesResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.listDevices = function listDevices(request, callback) {
                            return this.rpcCall(listDevices, $root.com.glowpush.glendon.admin.api.ListDevicesRequest, $root.com.glowpush.glendon.admin.api.ListDevicesResponse, request, callback);
                        }, "name", { value: "ListDevices" });

                        /**
                         * Calls ListDevices.
                         * @function listDevices
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListDevicesRequest} request ListDevicesRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.ListDevicesResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#listDeviceEvents}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef ListDeviceEventsCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.ListDeviceEventsResponse} [response] ListDeviceEventsResponse
                         */

                        /**
                         * Calls ListDeviceEvents.
                         * @function listDeviceEvents
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListDeviceEventsRequest} request ListDeviceEventsRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.ListDeviceEventsCallback} callback Node-style callback called with the error, if any, and ListDeviceEventsResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.listDeviceEvents = function listDeviceEvents(request, callback) {
                            return this.rpcCall(listDeviceEvents, $root.com.glowpush.glendon.admin.api.ListDeviceEventsRequest, $root.com.glowpush.glendon.admin.api.ListDeviceEventsResponse, request, callback);
                        }, "name", { value: "ListDeviceEvents" });

                        /**
                         * Calls ListDeviceEvents.
                         * @function listDeviceEvents
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListDeviceEventsRequest} request ListDeviceEventsRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.ListDeviceEventsResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#listDeviceDirectives}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef ListDeviceDirectivesCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.ListDeviceDirectivesResponse} [response] ListDeviceDirectivesResponse
                         */

                        /**
                         * Calls ListDeviceDirectives.
                         * @function listDeviceDirectives
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListDeviceDirectivesRequest} request ListDeviceDirectivesRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.ListDeviceDirectivesCallback} callback Node-style callback called with the error, if any, and ListDeviceDirectivesResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.listDeviceDirectives = function listDeviceDirectives(request, callback) {
                            return this.rpcCall(listDeviceDirectives, $root.com.glowpush.glendon.admin.api.ListDeviceDirectivesRequest, $root.com.glowpush.glendon.admin.api.ListDeviceDirectivesResponse, request, callback);
                        }, "name", { value: "ListDeviceDirectives" });

                        /**
                         * Calls ListDeviceDirectives.
                         * @function listDeviceDirectives
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListDeviceDirectivesRequest} request ListDeviceDirectivesRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.ListDeviceDirectivesResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#sendDirective}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef SendDirectiveCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.SendDirectiveResponse} [response] SendDirectiveResponse
                         */

                        /**
                         * Calls SendDirective.
                         * @function sendDirective
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ISendDirectiveRequest} request SendDirectiveRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.SendDirectiveCallback} callback Node-style callback called with the error, if any, and SendDirectiveResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.sendDirective = function sendDirective(request, callback) {
                            return this.rpcCall(sendDirective, $root.com.glowpush.glendon.admin.api.SendDirectiveRequest, $root.com.glowpush.glendon.admin.api.SendDirectiveResponse, request, callback);
                        }, "name", { value: "SendDirective" });

                        /**
                         * Calls SendDirective.
                         * @function sendDirective
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ISendDirectiveRequest} request SendDirectiveRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.SendDirectiveResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#trimArchives}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef TrimArchivesCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.TrimArchivesResponse} [response] TrimArchivesResponse
                         */

                        /**
                         * Calls TrimArchives.
                         * @function trimArchives
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ITrimArchivesRequest} request TrimArchivesRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.TrimArchivesCallback} callback Node-style callback called with the error, if any, and TrimArchivesResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.trimArchives = function trimArchives(request, callback) {
                            return this.rpcCall(trimArchives, $root.com.glowpush.glendon.admin.api.TrimArchivesRequest, $root.com.glowpush.glendon.admin.api.TrimArchivesResponse, request, callback);
                        }, "name", { value: "TrimArchives" });

                        /**
                         * Calls TrimArchives.
                         * @function trimArchives
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ITrimArchivesRequest} request TrimArchivesRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.TrimArchivesResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#addGroupDevice}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef AddGroupDeviceCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.AddGroupDeviceResponse} [response] AddGroupDeviceResponse
                         */

                        /**
                         * Calls AddGroupDevice.
                         * @function addGroupDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IAddGroupDeviceRequest} request AddGroupDeviceRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.AddGroupDeviceCallback} callback Node-style callback called with the error, if any, and AddGroupDeviceResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.addGroupDevice = function addGroupDevice(request, callback) {
                            return this.rpcCall(addGroupDevice, $root.com.glowpush.glendon.admin.api.AddGroupDeviceRequest, $root.com.glowpush.glendon.admin.api.AddGroupDeviceResponse, request, callback);
                        }, "name", { value: "AddGroupDevice" });

                        /**
                         * Calls AddGroupDevice.
                         * @function addGroupDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IAddGroupDeviceRequest} request AddGroupDeviceRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.AddGroupDeviceResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#removeGroupDevice}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef RemoveGroupDeviceCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.RemoveGroupDeviceResponse} [response] RemoveGroupDeviceResponse
                         */

                        /**
                         * Calls RemoveGroupDevice.
                         * @function removeGroupDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IRemoveGroupDeviceRequest} request RemoveGroupDeviceRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.RemoveGroupDeviceCallback} callback Node-style callback called with the error, if any, and RemoveGroupDeviceResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.removeGroupDevice = function removeGroupDevice(request, callback) {
                            return this.rpcCall(removeGroupDevice, $root.com.glowpush.glendon.admin.api.RemoveGroupDeviceRequest, $root.com.glowpush.glendon.admin.api.RemoveGroupDeviceResponse, request, callback);
                        }, "name", { value: "RemoveGroupDevice" });

                        /**
                         * Calls RemoveGroupDevice.
                         * @function removeGroupDevice
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IRemoveGroupDeviceRequest} request RemoveGroupDeviceRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.RemoveGroupDeviceResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#createGroup}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef CreateGroupCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.CreateGroupResponse} [response] CreateGroupResponse
                         */

                        /**
                         * Calls CreateGroup.
                         * @function createGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ICreateGroupRequest} request CreateGroupRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.CreateGroupCallback} callback Node-style callback called with the error, if any, and CreateGroupResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.createGroup = function createGroup(request, callback) {
                            return this.rpcCall(createGroup, $root.com.glowpush.glendon.admin.api.CreateGroupRequest, $root.com.glowpush.glendon.admin.api.CreateGroupResponse, request, callback);
                        }, "name", { value: "CreateGroup" });

                        /**
                         * Calls CreateGroup.
                         * @function createGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.ICreateGroupRequest} request CreateGroupRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.CreateGroupResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#deleteGroup}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef DeleteGroupCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.DeleteGroupResponse} [response] DeleteGroupResponse
                         */

                        /**
                         * Calls DeleteGroup.
                         * @function deleteGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IDeleteGroupRequest} request DeleteGroupRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.DeleteGroupCallback} callback Node-style callback called with the error, if any, and DeleteGroupResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.deleteGroup = function deleteGroup(request, callback) {
                            return this.rpcCall(deleteGroup, $root.com.glowpush.glendon.admin.api.DeleteGroupRequest, $root.com.glowpush.glendon.admin.api.DeleteGroupResponse, request, callback);
                        }, "name", { value: "DeleteGroup" });

                        /**
                         * Calls DeleteGroup.
                         * @function deleteGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IDeleteGroupRequest} request DeleteGroupRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.DeleteGroupResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#getGroup}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef GetGroupCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.GetGroupResponse} [response] GetGroupResponse
                         */

                        /**
                         * Calls GetGroup.
                         * @function getGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IGetGroupRequest} request GetGroupRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.GetGroupCallback} callback Node-style callback called with the error, if any, and GetGroupResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.getGroup = function getGroup(request, callback) {
                            return this.rpcCall(getGroup, $root.com.glowpush.glendon.admin.api.GetGroupRequest, $root.com.glowpush.glendon.admin.api.GetGroupResponse, request, callback);
                        }, "name", { value: "GetGroup" });

                        /**
                         * Calls GetGroup.
                         * @function getGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IGetGroupRequest} request GetGroupRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.GetGroupResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#listGroups}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef ListGroupsCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.ListGroupsResponse} [response] ListGroupsResponse
                         */

                        /**
                         * Calls ListGroups.
                         * @function listGroups
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListGroupsRequest} request ListGroupsRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.ListGroupsCallback} callback Node-style callback called with the error, if any, and ListGroupsResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.listGroups = function listGroups(request, callback) {
                            return this.rpcCall(listGroups, $root.com.glowpush.glendon.admin.api.ListGroupsRequest, $root.com.glowpush.glendon.admin.api.ListGroupsResponse, request, callback);
                        }, "name", { value: "ListGroups" });

                        /**
                         * Calls ListGroups.
                         * @function listGroups
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IListGroupsRequest} request ListGroupsRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.ListGroupsResponse>} Promise
                         * @variation 2
                         */

                        /**
                         * Callback as used by {@link com.glowpush.glendon.admin.api.AdminApi#broadcastGroup}.
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @typedef BroadcastGroupCallback
                         * @type {function}
                         * @param {Error|null} error Error, if any
                         * @param {com.glowpush.glendon.admin.api.BroadcastGroupResponse} [response] BroadcastGroupResponse
                         */

                        /**
                         * Calls BroadcastGroup.
                         * @function broadcastGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IBroadcastGroupRequest} request BroadcastGroupRequest message or plain object
                         * @param {com.glowpush.glendon.admin.api.AdminApi.BroadcastGroupCallback} callback Node-style callback called with the error, if any, and BroadcastGroupResponse
                         * @returns {undefined}
                         * @variation 1
                         */
                        Object.defineProperty(AdminApi.prototype.broadcastGroup = function broadcastGroup(request, callback) {
                            return this.rpcCall(broadcastGroup, $root.com.glowpush.glendon.admin.api.BroadcastGroupRequest, $root.com.glowpush.glendon.admin.api.BroadcastGroupResponse, request, callback);
                        }, "name", { value: "BroadcastGroup" });

                        /**
                         * Calls BroadcastGroup.
                         * @function broadcastGroup
                         * @memberof com.glowpush.glendon.admin.api.AdminApi
                         * @instance
                         * @param {com.glowpush.glendon.admin.api.IBroadcastGroupRequest} request BroadcastGroupRequest message or plain object
                         * @returns {Promise<com.glowpush.glendon.admin.api.BroadcastGroupResponse>} Promise
                         * @variation 2
                         */

                        return AdminApi;
                    })();

                    api.AddDeviceTagRequest = (function() {

                        /**
                         * Properties of an AddDeviceTagRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IAddDeviceTagRequest
                         * @property {string|null} [device_id] AddDeviceTagRequest device_id
                         * @property {string|null} [tag_text] AddDeviceTagRequest tag_text
                         */

                        /**
                         * Constructs a new AddDeviceTagRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents an AddDeviceTagRequest.
                         * @implements IAddDeviceTagRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IAddDeviceTagRequest=} [properties] Properties to set
                         */
                        function AddDeviceTagRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AddDeviceTagRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.AddDeviceTagRequest
                         * @instance
                         */
                        AddDeviceTagRequest.prototype.device_id = "";

                        /**
                         * AddDeviceTagRequest tag_text.
                         * @member {string} tag_text
                         * @memberof com.glowpush.glendon.admin.api.AddDeviceTagRequest
                         * @instance
                         */
                        AddDeviceTagRequest.prototype.tag_text = "";

                        return AddDeviceTagRequest;
                    })();

                    api.AddDeviceTagResponse = (function() {

                        /**
                         * Properties of an AddDeviceTagResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IAddDeviceTagResponse
                         * @property {string|null} [device_id] AddDeviceTagResponse device_id
                         * @property {string|null} [tag_text] AddDeviceTagResponse tag_text
                         */

                        /**
                         * Constructs a new AddDeviceTagResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents an AddDeviceTagResponse.
                         * @implements IAddDeviceTagResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IAddDeviceTagResponse=} [properties] Properties to set
                         */
                        function AddDeviceTagResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AddDeviceTagResponse device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.AddDeviceTagResponse
                         * @instance
                         */
                        AddDeviceTagResponse.prototype.device_id = "";

                        /**
                         * AddDeviceTagResponse tag_text.
                         * @member {string} tag_text
                         * @memberof com.glowpush.glendon.admin.api.AddDeviceTagResponse
                         * @instance
                         */
                        AddDeviceTagResponse.prototype.tag_text = "";

                        return AddDeviceTagResponse;
                    })();

                    api.RemoveDeviceTagRequest = (function() {

                        /**
                         * Properties of a RemoveDeviceTagRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IRemoveDeviceTagRequest
                         * @property {string|null} [device_id] RemoveDeviceTagRequest device_id
                         * @property {string|null} [tag_text] RemoveDeviceTagRequest tag_text
                         */

                        /**
                         * Constructs a new RemoveDeviceTagRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a RemoveDeviceTagRequest.
                         * @implements IRemoveDeviceTagRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IRemoveDeviceTagRequest=} [properties] Properties to set
                         */
                        function RemoveDeviceTagRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RemoveDeviceTagRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.RemoveDeviceTagRequest
                         * @instance
                         */
                        RemoveDeviceTagRequest.prototype.device_id = "";

                        /**
                         * RemoveDeviceTagRequest tag_text.
                         * @member {string} tag_text
                         * @memberof com.glowpush.glendon.admin.api.RemoveDeviceTagRequest
                         * @instance
                         */
                        RemoveDeviceTagRequest.prototype.tag_text = "";

                        return RemoveDeviceTagRequest;
                    })();

                    api.RemoveDeviceTagResponse = (function() {

                        /**
                         * Properties of a RemoveDeviceTagResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IRemoveDeviceTagResponse
                         * @property {string|null} [device_id] RemoveDeviceTagResponse device_id
                         * @property {string|null} [tag_text] RemoveDeviceTagResponse tag_text
                         */

                        /**
                         * Constructs a new RemoveDeviceTagResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a RemoveDeviceTagResponse.
                         * @implements IRemoveDeviceTagResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IRemoveDeviceTagResponse=} [properties] Properties to set
                         */
                        function RemoveDeviceTagResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RemoveDeviceTagResponse device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.RemoveDeviceTagResponse
                         * @instance
                         */
                        RemoveDeviceTagResponse.prototype.device_id = "";

                        /**
                         * RemoveDeviceTagResponse tag_text.
                         * @member {string} tag_text
                         * @memberof com.glowpush.glendon.admin.api.RemoveDeviceTagResponse
                         * @instance
                         */
                        RemoveDeviceTagResponse.prototype.tag_text = "";

                        return RemoveDeviceTagResponse;
                    })();

                    api.CreateDeviceRequest = (function() {

                        /**
                         * Properties of a CreateDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ICreateDeviceRequest
                         * @property {string|null} [device_id] CreateDeviceRequest device_id
                         */

                        /**
                         * Constructs a new CreateDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a CreateDeviceRequest.
                         * @implements ICreateDeviceRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ICreateDeviceRequest=} [properties] Properties to set
                         */
                        function CreateDeviceRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateDeviceRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.CreateDeviceRequest
                         * @instance
                         */
                        CreateDeviceRequest.prototype.device_id = "";

                        return CreateDeviceRequest;
                    })();

                    api.CreateDeviceResponse = (function() {

                        /**
                         * Properties of a CreateDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ICreateDeviceResponse
                         * @property {com.glowpush.glendon.admin.api.IDevice|null} [device] CreateDeviceResponse device
                         */

                        /**
                         * Constructs a new CreateDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a CreateDeviceResponse.
                         * @implements ICreateDeviceResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ICreateDeviceResponse=} [properties] Properties to set
                         */
                        function CreateDeviceResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateDeviceResponse device.
                         * @member {com.glowpush.glendon.admin.api.IDevice|null|undefined} device
                         * @memberof com.glowpush.glendon.admin.api.CreateDeviceResponse
                         * @instance
                         */
                        CreateDeviceResponse.prototype.device = null;

                        return CreateDeviceResponse;
                    })();

                    api.GetDeviceRequest = (function() {

                        /**
                         * Properties of a GetDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGetDeviceRequest
                         * @property {string|null} [device_id] GetDeviceRequest device_id
                         */

                        /**
                         * Constructs a new GetDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a GetDeviceRequest.
                         * @implements IGetDeviceRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGetDeviceRequest=} [properties] Properties to set
                         */
                        function GetDeviceRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDeviceRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.GetDeviceRequest
                         * @instance
                         */
                        GetDeviceRequest.prototype.device_id = "";

                        return GetDeviceRequest;
                    })();

                    api.GetDeviceResponse = (function() {

                        /**
                         * Properties of a GetDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGetDeviceResponse
                         * @property {com.glowpush.glendon.admin.api.IDevice|null} [device] GetDeviceResponse device
                         */

                        /**
                         * Constructs a new GetDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a GetDeviceResponse.
                         * @implements IGetDeviceResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGetDeviceResponse=} [properties] Properties to set
                         */
                        function GetDeviceResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDeviceResponse device.
                         * @member {com.glowpush.glendon.admin.api.IDevice|null|undefined} device
                         * @memberof com.glowpush.glendon.admin.api.GetDeviceResponse
                         * @instance
                         */
                        GetDeviceResponse.prototype.device = null;

                        return GetDeviceResponse;
                    })();

                    api.GetDirectiveRequest = (function() {

                        /**
                         * Properties of a GetDirectiveRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGetDirectiveRequest
                         * @property {string|null} [request_id] GetDirectiveRequest request_id
                         */

                        /**
                         * Constructs a new GetDirectiveRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a GetDirectiveRequest.
                         * @implements IGetDirectiveRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGetDirectiveRequest=} [properties] Properties to set
                         */
                        function GetDirectiveRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDirectiveRequest request_id.
                         * @member {string} request_id
                         * @memberof com.glowpush.glendon.admin.api.GetDirectiveRequest
                         * @instance
                         */
                        GetDirectiveRequest.prototype.request_id = "";

                        return GetDirectiveRequest;
                    })();

                    api.GetDirectiveResponse = (function() {

                        /**
                         * Properties of a GetDirectiveResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGetDirectiveResponse
                         * @property {com.glowpush.glendon.admin.api.IDirective|null} [directive] GetDirectiveResponse directive
                         */

                        /**
                         * Constructs a new GetDirectiveResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a GetDirectiveResponse.
                         * @implements IGetDirectiveResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGetDirectiveResponse=} [properties] Properties to set
                         */
                        function GetDirectiveResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetDirectiveResponse directive.
                         * @member {com.glowpush.glendon.admin.api.IDirective|null|undefined} directive
                         * @memberof com.glowpush.glendon.admin.api.GetDirectiveResponse
                         * @instance
                         */
                        GetDirectiveResponse.prototype.directive = null;

                        return GetDirectiveResponse;
                    })();

                    api.ListAllDeviceTagsRequest = (function() {

                        /**
                         * Properties of a ListAllDeviceTagsRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListAllDeviceTagsRequest
                         * @property {number|null} [page_size] ListAllDeviceTagsRequest page_size
                         * @property {string|null} [page_token] ListAllDeviceTagsRequest page_token
                         */

                        /**
                         * Constructs a new ListAllDeviceTagsRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListAllDeviceTagsRequest.
                         * @implements IListAllDeviceTagsRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListAllDeviceTagsRequest=} [properties] Properties to set
                         */
                        function ListAllDeviceTagsRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListAllDeviceTagsRequest page_size.
                         * @member {number} page_size
                         * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsRequest
                         * @instance
                         */
                        ListAllDeviceTagsRequest.prototype.page_size = 0;

                        /**
                         * ListAllDeviceTagsRequest page_token.
                         * @member {string} page_token
                         * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsRequest
                         * @instance
                         */
                        ListAllDeviceTagsRequest.prototype.page_token = "";

                        return ListAllDeviceTagsRequest;
                    })();

                    api.ListAllDeviceTagsResponse = (function() {

                        /**
                         * Properties of a ListAllDeviceTagsResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListAllDeviceTagsResponse
                         * @property {Array.<com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse.ITag>|null} [tags] ListAllDeviceTagsResponse tags
                         * @property {number|null} [total_size] ListAllDeviceTagsResponse total_size
                         * @property {string|null} [next_page_token] ListAllDeviceTagsResponse next_page_token
                         */

                        /**
                         * Constructs a new ListAllDeviceTagsResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListAllDeviceTagsResponse.
                         * @implements IListAllDeviceTagsResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListAllDeviceTagsResponse=} [properties] Properties to set
                         */
                        function ListAllDeviceTagsResponse(properties) {
                            this.tags = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListAllDeviceTagsResponse tags.
                         * @member {Array.<com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse.ITag>} tags
                         * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse
                         * @instance
                         */
                        ListAllDeviceTagsResponse.prototype.tags = $util.emptyArray;

                        /**
                         * ListAllDeviceTagsResponse total_size.
                         * @member {number} total_size
                         * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse
                         * @instance
                         */
                        ListAllDeviceTagsResponse.prototype.total_size = 0;

                        /**
                         * ListAllDeviceTagsResponse next_page_token.
                         * @member {string} next_page_token
                         * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse
                         * @instance
                         */
                        ListAllDeviceTagsResponse.prototype.next_page_token = "";

                        ListAllDeviceTagsResponse.Tag = (function() {

                            /**
                             * Properties of a Tag.
                             * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse
                             * @interface ITag
                             * @property {string|null} [tag_text] Tag tag_text
                             * @property {number|null} [device_count] Tag device_count
                             */

                            /**
                             * Constructs a new Tag.
                             * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse
                             * @classdesc Represents a Tag.
                             * @implements ITag
                             * @constructor
                             * @param {com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse.ITag=} [properties] Properties to set
                             */
                            function Tag(properties) {
                                if (properties)
                                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * Tag tag_text.
                             * @member {string} tag_text
                             * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse.Tag
                             * @instance
                             */
                            Tag.prototype.tag_text = "";

                            /**
                             * Tag device_count.
                             * @member {number} device_count
                             * @memberof com.glowpush.glendon.admin.api.ListAllDeviceTagsResponse.Tag
                             * @instance
                             */
                            Tag.prototype.device_count = 0;

                            return Tag;
                        })();

                        return ListAllDeviceTagsResponse;
                    })();

                    api.ListDevicesRequest = (function() {

                        /**
                         * Properties of a ListDevicesRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListDevicesRequest
                         * @property {Array.<string>|null} [tag_texts] ListDevicesRequest tag_texts
                         * @property {number|null} [page_size] ListDevicesRequest page_size
                         * @property {string|null} [page_token] ListDevicesRequest page_token
                         */

                        /**
                         * Constructs a new ListDevicesRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListDevicesRequest.
                         * @implements IListDevicesRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListDevicesRequest=} [properties] Properties to set
                         */
                        function ListDevicesRequest(properties) {
                            this.tag_texts = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListDevicesRequest tag_texts.
                         * @member {Array.<string>} tag_texts
                         * @memberof com.glowpush.glendon.admin.api.ListDevicesRequest
                         * @instance
                         */
                        ListDevicesRequest.prototype.tag_texts = $util.emptyArray;

                        /**
                         * ListDevicesRequest page_size.
                         * @member {number} page_size
                         * @memberof com.glowpush.glendon.admin.api.ListDevicesRequest
                         * @instance
                         */
                        ListDevicesRequest.prototype.page_size = 0;

                        /**
                         * ListDevicesRequest page_token.
                         * @member {string} page_token
                         * @memberof com.glowpush.glendon.admin.api.ListDevicesRequest
                         * @instance
                         */
                        ListDevicesRequest.prototype.page_token = "";

                        return ListDevicesRequest;
                    })();

                    api.ListDevicesResponse = (function() {

                        /**
                         * Properties of a ListDevicesResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListDevicesResponse
                         * @property {Array.<com.glowpush.glendon.admin.api.IDevice>|null} [devices] ListDevicesResponse devices
                         * @property {number|null} [total_size] ListDevicesResponse total_size
                         * @property {string|null} [next_page_token] ListDevicesResponse next_page_token
                         */

                        /**
                         * Constructs a new ListDevicesResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListDevicesResponse.
                         * @implements IListDevicesResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListDevicesResponse=} [properties] Properties to set
                         */
                        function ListDevicesResponse(properties) {
                            this.devices = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListDevicesResponse devices.
                         * @member {Array.<com.glowpush.glendon.admin.api.IDevice>} devices
                         * @memberof com.glowpush.glendon.admin.api.ListDevicesResponse
                         * @instance
                         */
                        ListDevicesResponse.prototype.devices = $util.emptyArray;

                        /**
                         * ListDevicesResponse total_size.
                         * @member {number} total_size
                         * @memberof com.glowpush.glendon.admin.api.ListDevicesResponse
                         * @instance
                         */
                        ListDevicesResponse.prototype.total_size = 0;

                        /**
                         * ListDevicesResponse next_page_token.
                         * @member {string} next_page_token
                         * @memberof com.glowpush.glendon.admin.api.ListDevicesResponse
                         * @instance
                         */
                        ListDevicesResponse.prototype.next_page_token = "";

                        return ListDevicesResponse;
                    })();

                    api.ListDeviceEventsRequest = (function() {

                        /**
                         * Properties of a ListDeviceEventsRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListDeviceEventsRequest
                         * @property {string|null} [device_id] ListDeviceEventsRequest device_id
                         * @property {number|null} [page_size] ListDeviceEventsRequest page_size
                         * @property {string|null} [page_token] ListDeviceEventsRequest page_token
                         */

                        /**
                         * Constructs a new ListDeviceEventsRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListDeviceEventsRequest.
                         * @implements IListDeviceEventsRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListDeviceEventsRequest=} [properties] Properties to set
                         */
                        function ListDeviceEventsRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListDeviceEventsRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceEventsRequest
                         * @instance
                         */
                        ListDeviceEventsRequest.prototype.device_id = "";

                        /**
                         * ListDeviceEventsRequest page_size.
                         * @member {number} page_size
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceEventsRequest
                         * @instance
                         */
                        ListDeviceEventsRequest.prototype.page_size = 0;

                        /**
                         * ListDeviceEventsRequest page_token.
                         * @member {string} page_token
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceEventsRequest
                         * @instance
                         */
                        ListDeviceEventsRequest.prototype.page_token = "";

                        return ListDeviceEventsRequest;
                    })();

                    api.ListDeviceEventsResponse = (function() {

                        /**
                         * Properties of a ListDeviceEventsResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListDeviceEventsResponse
                         * @property {Array.<com.glowpush.glendon.admin.api.IEvent>|null} [events] ListDeviceEventsResponse events
                         * @property {number|null} [total_size] ListDeviceEventsResponse total_size
                         * @property {string|null} [next_page_token] ListDeviceEventsResponse next_page_token
                         */

                        /**
                         * Constructs a new ListDeviceEventsResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListDeviceEventsResponse.
                         * @implements IListDeviceEventsResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListDeviceEventsResponse=} [properties] Properties to set
                         */
                        function ListDeviceEventsResponse(properties) {
                            this.events = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListDeviceEventsResponse events.
                         * @member {Array.<com.glowpush.glendon.admin.api.IEvent>} events
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceEventsResponse
                         * @instance
                         */
                        ListDeviceEventsResponse.prototype.events = $util.emptyArray;

                        /**
                         * ListDeviceEventsResponse total_size.
                         * @member {number} total_size
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceEventsResponse
                         * @instance
                         */
                        ListDeviceEventsResponse.prototype.total_size = 0;

                        /**
                         * ListDeviceEventsResponse next_page_token.
                         * @member {string} next_page_token
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceEventsResponse
                         * @instance
                         */
                        ListDeviceEventsResponse.prototype.next_page_token = "";

                        return ListDeviceEventsResponse;
                    })();

                    api.ListDeviceDirectivesRequest = (function() {

                        /**
                         * Properties of a ListDeviceDirectivesRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListDeviceDirectivesRequest
                         * @property {string|null} [device_id] ListDeviceDirectivesRequest device_id
                         * @property {number|null} [page_size] ListDeviceDirectivesRequest page_size
                         * @property {string|null} [page_token] ListDeviceDirectivesRequest page_token
                         */

                        /**
                         * Constructs a new ListDeviceDirectivesRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListDeviceDirectivesRequest.
                         * @implements IListDeviceDirectivesRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListDeviceDirectivesRequest=} [properties] Properties to set
                         */
                        function ListDeviceDirectivesRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListDeviceDirectivesRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceDirectivesRequest
                         * @instance
                         */
                        ListDeviceDirectivesRequest.prototype.device_id = "";

                        /**
                         * ListDeviceDirectivesRequest page_size.
                         * @member {number} page_size
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceDirectivesRequest
                         * @instance
                         */
                        ListDeviceDirectivesRequest.prototype.page_size = 0;

                        /**
                         * ListDeviceDirectivesRequest page_token.
                         * @member {string} page_token
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceDirectivesRequest
                         * @instance
                         */
                        ListDeviceDirectivesRequest.prototype.page_token = "";

                        return ListDeviceDirectivesRequest;
                    })();

                    api.ListDeviceDirectivesResponse = (function() {

                        /**
                         * Properties of a ListDeviceDirectivesResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListDeviceDirectivesResponse
                         * @property {Array.<com.glowpush.glendon.admin.api.IDirective>|null} [directives] ListDeviceDirectivesResponse directives
                         * @property {number|null} [total_size] ListDeviceDirectivesResponse total_size
                         * @property {string|null} [next_page_token] ListDeviceDirectivesResponse next_page_token
                         */

                        /**
                         * Constructs a new ListDeviceDirectivesResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListDeviceDirectivesResponse.
                         * @implements IListDeviceDirectivesResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListDeviceDirectivesResponse=} [properties] Properties to set
                         */
                        function ListDeviceDirectivesResponse(properties) {
                            this.directives = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListDeviceDirectivesResponse directives.
                         * @member {Array.<com.glowpush.glendon.admin.api.IDirective>} directives
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceDirectivesResponse
                         * @instance
                         */
                        ListDeviceDirectivesResponse.prototype.directives = $util.emptyArray;

                        /**
                         * ListDeviceDirectivesResponse total_size.
                         * @member {number} total_size
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceDirectivesResponse
                         * @instance
                         */
                        ListDeviceDirectivesResponse.prototype.total_size = 0;

                        /**
                         * ListDeviceDirectivesResponse next_page_token.
                         * @member {string} next_page_token
                         * @memberof com.glowpush.glendon.admin.api.ListDeviceDirectivesResponse
                         * @instance
                         */
                        ListDeviceDirectivesResponse.prototype.next_page_token = "";

                        return ListDeviceDirectivesResponse;
                    })();

                    api.SendDirectiveRequest = (function() {

                        /**
                         * Properties of a SendDirectiveRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ISendDirectiveRequest
                         * @property {string|null} [device_id] SendDirectiveRequest device_id
                         * @property {com.glowpush.glendon.topics.Directive|null} [directive] SendDirectiveRequest directive
                         * @property {Uint8Array|null} [request] SendDirectiveRequest request
                         */

                        /**
                         * Constructs a new SendDirectiveRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a SendDirectiveRequest.
                         * @implements ISendDirectiveRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ISendDirectiveRequest=} [properties] Properties to set
                         */
                        function SendDirectiveRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SendDirectiveRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.SendDirectiveRequest
                         * @instance
                         */
                        SendDirectiveRequest.prototype.device_id = "";

                        /**
                         * SendDirectiveRequest directive.
                         * @member {com.glowpush.glendon.topics.Directive} directive
                         * @memberof com.glowpush.glendon.admin.api.SendDirectiveRequest
                         * @instance
                         */
                        SendDirectiveRequest.prototype.directive = 0;

                        /**
                         * SendDirectiveRequest request.
                         * @member {Uint8Array} request
                         * @memberof com.glowpush.glendon.admin.api.SendDirectiveRequest
                         * @instance
                         */
                        SendDirectiveRequest.prototype.request = $util.newBuffer([]);

                        return SendDirectiveRequest;
                    })();

                    api.SendDirectiveResponse = (function() {

                        /**
                         * Properties of a SendDirectiveResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ISendDirectiveResponse
                         * @property {string|null} [request_id] SendDirectiveResponse request_id
                         */

                        /**
                         * Constructs a new SendDirectiveResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a SendDirectiveResponse.
                         * @implements ISendDirectiveResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ISendDirectiveResponse=} [properties] Properties to set
                         */
                        function SendDirectiveResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * SendDirectiveResponse request_id.
                         * @member {string} request_id
                         * @memberof com.glowpush.glendon.admin.api.SendDirectiveResponse
                         * @instance
                         */
                        SendDirectiveResponse.prototype.request_id = "";

                        return SendDirectiveResponse;
                    })();

                    api.TrimArchivesRequest = (function() {

                        /**
                         * Properties of a TrimArchivesRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ITrimArchivesRequest
                         * @property {number|null} [since_hours_ago] TrimArchivesRequest since_hours_ago
                         */

                        /**
                         * Constructs a new TrimArchivesRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a TrimArchivesRequest.
                         * @implements ITrimArchivesRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ITrimArchivesRequest=} [properties] Properties to set
                         */
                        function TrimArchivesRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * TrimArchivesRequest since_hours_ago.
                         * @member {number} since_hours_ago
                         * @memberof com.glowpush.glendon.admin.api.TrimArchivesRequest
                         * @instance
                         */
                        TrimArchivesRequest.prototype.since_hours_ago = 0;

                        return TrimArchivesRequest;
                    })();

                    api.TrimArchivesResponse = (function() {

                        /**
                         * Properties of a TrimArchivesResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ITrimArchivesResponse
                         */

                        /**
                         * Constructs a new TrimArchivesResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a TrimArchivesResponse.
                         * @implements ITrimArchivesResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ITrimArchivesResponse=} [properties] Properties to set
                         */
                        function TrimArchivesResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        return TrimArchivesResponse;
                    })();

                    api.CreateGroupRequest = (function() {

                        /**
                         * Properties of a CreateGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ICreateGroupRequest
                         * @property {string|null} [group_name] CreateGroupRequest group_name
                         */

                        /**
                         * Constructs a new CreateGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a CreateGroupRequest.
                         * @implements ICreateGroupRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ICreateGroupRequest=} [properties] Properties to set
                         */
                        function CreateGroupRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateGroupRequest group_name.
                         * @member {string} group_name
                         * @memberof com.glowpush.glendon.admin.api.CreateGroupRequest
                         * @instance
                         */
                        CreateGroupRequest.prototype.group_name = "";

                        return CreateGroupRequest;
                    })();

                    api.CreateGroupResponse = (function() {

                        /**
                         * Properties of a CreateGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface ICreateGroupResponse
                         * @property {com.glowpush.glendon.admin.api.IGroup|null} [group] CreateGroupResponse group
                         */

                        /**
                         * Constructs a new CreateGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a CreateGroupResponse.
                         * @implements ICreateGroupResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.ICreateGroupResponse=} [properties] Properties to set
                         */
                        function CreateGroupResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * CreateGroupResponse group.
                         * @member {com.glowpush.glendon.admin.api.IGroup|null|undefined} group
                         * @memberof com.glowpush.glendon.admin.api.CreateGroupResponse
                         * @instance
                         */
                        CreateGroupResponse.prototype.group = null;

                        return CreateGroupResponse;
                    })();

                    api.DeleteGroupRequest = (function() {

                        /**
                         * Properties of a DeleteGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IDeleteGroupRequest
                         * @property {string|null} [group_id] DeleteGroupRequest group_id
                         */

                        /**
                         * Constructs a new DeleteGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a DeleteGroupRequest.
                         * @implements IDeleteGroupRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IDeleteGroupRequest=} [properties] Properties to set
                         */
                        function DeleteGroupRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DeleteGroupRequest group_id.
                         * @member {string} group_id
                         * @memberof com.glowpush.glendon.admin.api.DeleteGroupRequest
                         * @instance
                         */
                        DeleteGroupRequest.prototype.group_id = "";

                        return DeleteGroupRequest;
                    })();

                    api.DeleteGroupResponse = (function() {

                        /**
                         * Properties of a DeleteGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IDeleteGroupResponse
                         */

                        /**
                         * Constructs a new DeleteGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a DeleteGroupResponse.
                         * @implements IDeleteGroupResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IDeleteGroupResponse=} [properties] Properties to set
                         */
                        function DeleteGroupResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        return DeleteGroupResponse;
                    })();

                    api.GetGroupRequest = (function() {

                        /**
                         * Properties of a GetGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGetGroupRequest
                         * @property {string|null} [group_id] GetGroupRequest group_id
                         */

                        /**
                         * Constructs a new GetGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a GetGroupRequest.
                         * @implements IGetGroupRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGetGroupRequest=} [properties] Properties to set
                         */
                        function GetGroupRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetGroupRequest group_id.
                         * @member {string} group_id
                         * @memberof com.glowpush.glendon.admin.api.GetGroupRequest
                         * @instance
                         */
                        GetGroupRequest.prototype.group_id = "";

                        return GetGroupRequest;
                    })();

                    api.GetGroupResponse = (function() {

                        /**
                         * Properties of a GetGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGetGroupResponse
                         * @property {com.glowpush.glendon.admin.api.IGroup|null} [group] GetGroupResponse group
                         */

                        /**
                         * Constructs a new GetGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a GetGroupResponse.
                         * @implements IGetGroupResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGetGroupResponse=} [properties] Properties to set
                         */
                        function GetGroupResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * GetGroupResponse group.
                         * @member {com.glowpush.glendon.admin.api.IGroup|null|undefined} group
                         * @memberof com.glowpush.glendon.admin.api.GetGroupResponse
                         * @instance
                         */
                        GetGroupResponse.prototype.group = null;

                        return GetGroupResponse;
                    })();

                    api.ListGroupsRequest = (function() {

                        /**
                         * Properties of a ListGroupsRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListGroupsRequest
                         * @property {string|null} [device_id] ListGroupsRequest device_id
                         * @property {number|null} [page_size] ListGroupsRequest page_size
                         * @property {string|null} [page_token] ListGroupsRequest page_token
                         */

                        /**
                         * Constructs a new ListGroupsRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListGroupsRequest.
                         * @implements IListGroupsRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListGroupsRequest=} [properties] Properties to set
                         */
                        function ListGroupsRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListGroupsRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.ListGroupsRequest
                         * @instance
                         */
                        ListGroupsRequest.prototype.device_id = "";

                        /**
                         * ListGroupsRequest page_size.
                         * @member {number} page_size
                         * @memberof com.glowpush.glendon.admin.api.ListGroupsRequest
                         * @instance
                         */
                        ListGroupsRequest.prototype.page_size = 0;

                        /**
                         * ListGroupsRequest page_token.
                         * @member {string} page_token
                         * @memberof com.glowpush.glendon.admin.api.ListGroupsRequest
                         * @instance
                         */
                        ListGroupsRequest.prototype.page_token = "";

                        return ListGroupsRequest;
                    })();

                    api.ListGroupsResponse = (function() {

                        /**
                         * Properties of a ListGroupsResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IListGroupsResponse
                         * @property {Array.<com.glowpush.glendon.admin.api.IGroup>|null} [groups] ListGroupsResponse groups
                         * @property {number|null} [total_size] ListGroupsResponse total_size
                         * @property {string|null} [next_page_token] ListGroupsResponse next_page_token
                         */

                        /**
                         * Constructs a new ListGroupsResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a ListGroupsResponse.
                         * @implements IListGroupsResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IListGroupsResponse=} [properties] Properties to set
                         */
                        function ListGroupsResponse(properties) {
                            this.groups = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ListGroupsResponse groups.
                         * @member {Array.<com.glowpush.glendon.admin.api.IGroup>} groups
                         * @memberof com.glowpush.glendon.admin.api.ListGroupsResponse
                         * @instance
                         */
                        ListGroupsResponse.prototype.groups = $util.emptyArray;

                        /**
                         * ListGroupsResponse total_size.
                         * @member {number} total_size
                         * @memberof com.glowpush.glendon.admin.api.ListGroupsResponse
                         * @instance
                         */
                        ListGroupsResponse.prototype.total_size = 0;

                        /**
                         * ListGroupsResponse next_page_token.
                         * @member {string} next_page_token
                         * @memberof com.glowpush.glendon.admin.api.ListGroupsResponse
                         * @instance
                         */
                        ListGroupsResponse.prototype.next_page_token = "";

                        return ListGroupsResponse;
                    })();

                    api.AddGroupDeviceRequest = (function() {

                        /**
                         * Properties of an AddGroupDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IAddGroupDeviceRequest
                         * @property {string|null} [group_id] AddGroupDeviceRequest group_id
                         * @property {string|null} [device_id] AddGroupDeviceRequest device_id
                         * @property {number|null} [group_device_color] AddGroupDeviceRequest group_device_color
                         */

                        /**
                         * Constructs a new AddGroupDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents an AddGroupDeviceRequest.
                         * @implements IAddGroupDeviceRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IAddGroupDeviceRequest=} [properties] Properties to set
                         */
                        function AddGroupDeviceRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AddGroupDeviceRequest group_id.
                         * @member {string} group_id
                         * @memberof com.glowpush.glendon.admin.api.AddGroupDeviceRequest
                         * @instance
                         */
                        AddGroupDeviceRequest.prototype.group_id = "";

                        /**
                         * AddGroupDeviceRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.AddGroupDeviceRequest
                         * @instance
                         */
                        AddGroupDeviceRequest.prototype.device_id = "";

                        /**
                         * AddGroupDeviceRequest group_device_color.
                         * @member {number} group_device_color
                         * @memberof com.glowpush.glendon.admin.api.AddGroupDeviceRequest
                         * @instance
                         */
                        AddGroupDeviceRequest.prototype.group_device_color = 0;

                        return AddGroupDeviceRequest;
                    })();

                    api.AddGroupDeviceResponse = (function() {

                        /**
                         * Properties of an AddGroupDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IAddGroupDeviceResponse
                         */

                        /**
                         * Constructs a new AddGroupDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents an AddGroupDeviceResponse.
                         * @implements IAddGroupDeviceResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IAddGroupDeviceResponse=} [properties] Properties to set
                         */
                        function AddGroupDeviceResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        return AddGroupDeviceResponse;
                    })();

                    api.RemoveGroupDeviceRequest = (function() {

                        /**
                         * Properties of a RemoveGroupDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IRemoveGroupDeviceRequest
                         * @property {string|null} [group_id] RemoveGroupDeviceRequest group_id
                         * @property {string|null} [device_id] RemoveGroupDeviceRequest device_id
                         */

                        /**
                         * Constructs a new RemoveGroupDeviceRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a RemoveGroupDeviceRequest.
                         * @implements IRemoveGroupDeviceRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IRemoveGroupDeviceRequest=} [properties] Properties to set
                         */
                        function RemoveGroupDeviceRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * RemoveGroupDeviceRequest group_id.
                         * @member {string} group_id
                         * @memberof com.glowpush.glendon.admin.api.RemoveGroupDeviceRequest
                         * @instance
                         */
                        RemoveGroupDeviceRequest.prototype.group_id = "";

                        /**
                         * RemoveGroupDeviceRequest device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.RemoveGroupDeviceRequest
                         * @instance
                         */
                        RemoveGroupDeviceRequest.prototype.device_id = "";

                        return RemoveGroupDeviceRequest;
                    })();

                    api.RemoveGroupDeviceResponse = (function() {

                        /**
                         * Properties of a RemoveGroupDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IRemoveGroupDeviceResponse
                         */

                        /**
                         * Constructs a new RemoveGroupDeviceResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a RemoveGroupDeviceResponse.
                         * @implements IRemoveGroupDeviceResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IRemoveGroupDeviceResponse=} [properties] Properties to set
                         */
                        function RemoveGroupDeviceResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        return RemoveGroupDeviceResponse;
                    })();

                    api.BroadcastGroupRequest = (function() {

                        /**
                         * Properties of a BroadcastGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IBroadcastGroupRequest
                         * @property {string|null} [group_id] BroadcastGroupRequest group_id
                         */

                        /**
                         * Constructs a new BroadcastGroupRequest.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a BroadcastGroupRequest.
                         * @implements IBroadcastGroupRequest
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IBroadcastGroupRequest=} [properties] Properties to set
                         */
                        function BroadcastGroupRequest(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * BroadcastGroupRequest group_id.
                         * @member {string} group_id
                         * @memberof com.glowpush.glendon.admin.api.BroadcastGroupRequest
                         * @instance
                         */
                        BroadcastGroupRequest.prototype.group_id = "";

                        return BroadcastGroupRequest;
                    })();

                    api.BroadcastGroupResponse = (function() {

                        /**
                         * Properties of a BroadcastGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IBroadcastGroupResponse
                         */

                        /**
                         * Constructs a new BroadcastGroupResponse.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a BroadcastGroupResponse.
                         * @implements IBroadcastGroupResponse
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IBroadcastGroupResponse=} [properties] Properties to set
                         */
                        function BroadcastGroupResponse(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        return BroadcastGroupResponse;
                    })();

                    api.Device = (function() {

                        /**
                         * Properties of a Device.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IDevice
                         * @property {string|null} [device_id] Device device_id
                         * @property {string|null} [hardware_identifier] Device hardware_identifier
                         * @property {number|null} [created_at] Device created_at
                         * @property {Array.<string>|null} [tag_texts] Device tag_texts
                         */

                        /**
                         * Constructs a new Device.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a Device.
                         * @implements IDevice
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IDevice=} [properties] Properties to set
                         */
                        function Device(properties) {
                            this.tag_texts = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Device device_id.
                         * @member {string} device_id
                         * @memberof com.glowpush.glendon.admin.api.Device
                         * @instance
                         */
                        Device.prototype.device_id = "";

                        /**
                         * Device hardware_identifier.
                         * @member {string} hardware_identifier
                         * @memberof com.glowpush.glendon.admin.api.Device
                         * @instance
                         */
                        Device.prototype.hardware_identifier = "";

                        /**
                         * Device created_at.
                         * @member {number} created_at
                         * @memberof com.glowpush.glendon.admin.api.Device
                         * @instance
                         */
                        Device.prototype.created_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Device tag_texts.
                         * @member {Array.<string>} tag_texts
                         * @memberof com.glowpush.glendon.admin.api.Device
                         * @instance
                         */
                        Device.prototype.tag_texts = $util.emptyArray;

                        return Device;
                    })();

                    api.Group = (function() {

                        /**
                         * Properties of a Group.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IGroup
                         * @property {string|null} [group_id] Group group_id
                         * @property {string|null} [group_name] Group group_name
                         * @property {Array.<com.glowpush.glendon.admin.api.Group.IDevice>|null} [devices] Group devices
                         * @property {number|null} [created_at] Group created_at
                         */

                        /**
                         * Constructs a new Group.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a Group.
                         * @implements IGroup
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IGroup=} [properties] Properties to set
                         */
                        function Group(properties) {
                            this.devices = [];
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Group group_id.
                         * @member {string} group_id
                         * @memberof com.glowpush.glendon.admin.api.Group
                         * @instance
                         */
                        Group.prototype.group_id = "";

                        /**
                         * Group group_name.
                         * @member {string} group_name
                         * @memberof com.glowpush.glendon.admin.api.Group
                         * @instance
                         */
                        Group.prototype.group_name = "";

                        /**
                         * Group devices.
                         * @member {Array.<com.glowpush.glendon.admin.api.Group.IDevice>} devices
                         * @memberof com.glowpush.glendon.admin.api.Group
                         * @instance
                         */
                        Group.prototype.devices = $util.emptyArray;

                        /**
                         * Group created_at.
                         * @member {number} created_at
                         * @memberof com.glowpush.glendon.admin.api.Group
                         * @instance
                         */
                        Group.prototype.created_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        Group.Device = (function() {

                            /**
                             * Properties of a Device.
                             * @memberof com.glowpush.glendon.admin.api.Group
                             * @interface IDevice
                             * @property {string|null} [device_id] Device device_id
                             * @property {number|null} [group_device_color] Device group_device_color
                             */

                            /**
                             * Constructs a new Device.
                             * @memberof com.glowpush.glendon.admin.api.Group
                             * @classdesc Represents a Device.
                             * @implements IDevice
                             * @constructor
                             * @param {com.glowpush.glendon.admin.api.Group.IDevice=} [properties] Properties to set
                             */
                            function Device(properties) {
                                if (properties)
                                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * Device device_id.
                             * @member {string} device_id
                             * @memberof com.glowpush.glendon.admin.api.Group.Device
                             * @instance
                             */
                            Device.prototype.device_id = "";

                            /**
                             * Device group_device_color.
                             * @member {number} group_device_color
                             * @memberof com.glowpush.glendon.admin.api.Group.Device
                             * @instance
                             */
                            Device.prototype.group_device_color = 0;

                            return Device;
                        })();

                        return Group;
                    })();

                    api.Directive = (function() {

                        /**
                         * Properties of a Directive.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IDirective
                         * @property {string|null} [request_id] Directive request_id
                         * @property {com.glowpush.glendon.topics.Directive|null} [directive] Directive directive
                         * @property {com.glowpush.glendon.topics.IDirectiveRequestPayload|null} [directive_req_payload] Directive directive_req_payload
                         * @property {com.glowpush.glendon.topics.IDirectiveResponsePayload|null} [directive_res_payload] Directive directive_res_payload
                         * @property {number|null} [device_responded_at] Directive device_responded_at
                         * @property {number|null} [created_at] Directive created_at
                         * @property {number|null} [updated_at] Directive updated_at
                         */

                        /**
                         * Constructs a new Directive.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents a Directive.
                         * @implements IDirective
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IDirective=} [properties] Properties to set
                         */
                        function Directive(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Directive request_id.
                         * @member {string} request_id
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.request_id = "";

                        /**
                         * Directive directive.
                         * @member {com.glowpush.glendon.topics.Directive} directive
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.directive = 0;

                        /**
                         * Directive directive_req_payload.
                         * @member {com.glowpush.glendon.topics.IDirectiveRequestPayload|null|undefined} directive_req_payload
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.directive_req_payload = null;

                        /**
                         * Directive directive_res_payload.
                         * @member {com.glowpush.glendon.topics.IDirectiveResponsePayload|null|undefined} directive_res_payload
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.directive_res_payload = null;

                        /**
                         * Directive device_responded_at.
                         * @member {number} device_responded_at
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.device_responded_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Directive created_at.
                         * @member {number} created_at
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.created_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Directive updated_at.
                         * @member {number} updated_at
                         * @memberof com.glowpush.glendon.admin.api.Directive
                         * @instance
                         */
                        Directive.prototype.updated_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        return Directive;
                    })();

                    api.Event = (function() {

                        /**
                         * Properties of an Event.
                         * @memberof com.glowpush.glendon.admin.api
                         * @interface IEvent
                         * @property {string|null} [event_id] Event event_id
                         * @property {com.glowpush.glendon.topics.Event|null} [event] Event event
                         * @property {com.glowpush.glendon.topics.IEventPayload|null} [event_payload] Event event_payload
                         * @property {number|null} [device_emitted_at] Event device_emitted_at
                         * @property {number|null} [created_at] Event created_at
                         */

                        /**
                         * Constructs a new Event.
                         * @memberof com.glowpush.glendon.admin.api
                         * @classdesc Represents an Event.
                         * @implements IEvent
                         * @constructor
                         * @param {com.glowpush.glendon.admin.api.IEvent=} [properties] Properties to set
                         */
                        function Event(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Event event_id.
                         * @member {string} event_id
                         * @memberof com.glowpush.glendon.admin.api.Event
                         * @instance
                         */
                        Event.prototype.event_id = "";

                        /**
                         * Event event.
                         * @member {com.glowpush.glendon.topics.Event} event
                         * @memberof com.glowpush.glendon.admin.api.Event
                         * @instance
                         */
                        Event.prototype.event = 0;

                        /**
                         * Event event_payload.
                         * @member {com.glowpush.glendon.topics.IEventPayload|null|undefined} event_payload
                         * @memberof com.glowpush.glendon.admin.api.Event
                         * @instance
                         */
                        Event.prototype.event_payload = null;

                        /**
                         * Event device_emitted_at.
                         * @member {number} device_emitted_at
                         * @memberof com.glowpush.glendon.admin.api.Event
                         * @instance
                         */
                        Event.prototype.device_emitted_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Event created_at.
                         * @member {number} created_at
                         * @memberof com.glowpush.glendon.admin.api.Event
                         * @instance
                         */
                        Event.prototype.created_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        return Event;
                    })();

                    return api;
                })();

                return admin;
            })();

            glendon.topics = (function() {

                /**
                 * Namespace topics.
                 * @memberof com.glowpush.glendon
                 * @namespace
                 */
                const topics = {};

                /**
                 * Directive enum.
                 * @name com.glowpush.glendon.topics.Directive
                 * @enum {number}
                 * @property {number} DIRECTIVE_UNSPECIFIED=0 DIRECTIVE_UNSPECIFIED value
                 * @property {number} FETCH_STATUS=1 FETCH_STATUS value
                 * @property {number} UPDATE_OPERATING_MODE=2 UPDATE_OPERATING_MODE value
                 * @property {number} FETCH_NETWORK_CONFIG=3 FETCH_NETWORK_CONFIG value
                 * @property {number} UPDATE_NETWORK_CONFIG=4 UPDATE_NETWORK_CONFIG value
                 * @property {number} REBOOT=5 REBOOT value
                 * @property {number} FETCH_FIRMWARE_DETAILS=6 FETCH_FIRMWARE_DETAILS value
                 * @property {number} FETCH_LIGHTS=7 FETCH_LIGHTS value
                 * @property {number} UPDATE_LIGHTS=8 UPDATE_LIGHTS value
                 * @property {number} UPDATE_GROUP_STATE=9 UPDATE_GROUP_STATE value
                 */
                topics.Directive = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DIRECTIVE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "FETCH_STATUS"] = 1;
                    values[valuesById[2] = "UPDATE_OPERATING_MODE"] = 2;
                    values[valuesById[3] = "FETCH_NETWORK_CONFIG"] = 3;
                    values[valuesById[4] = "UPDATE_NETWORK_CONFIG"] = 4;
                    values[valuesById[5] = "REBOOT"] = 5;
                    values[valuesById[6] = "FETCH_FIRMWARE_DETAILS"] = 6;
                    values[valuesById[7] = "FETCH_LIGHTS"] = 7;
                    values[valuesById[8] = "UPDATE_LIGHTS"] = 8;
                    values[valuesById[9] = "UPDATE_GROUP_STATE"] = 9;
                    return values;
                })();

                topics.UpdateGroupStateRequest = (function() {

                    /**
                     * Properties of an UpdateGroupStateRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateGroupStateRequest
                     * @property {string|null} [my_member_id] UpdateGroupStateRequest my_member_id
                     * @property {com.glowpush.glendon.topics.IGroupState|null} [group_state] UpdateGroupStateRequest group_state
                     */

                    /**
                     * Constructs a new UpdateGroupStateRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateGroupStateRequest.
                     * @implements IUpdateGroupStateRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateGroupStateRequest=} [properties] Properties to set
                     */
                    function UpdateGroupStateRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UpdateGroupStateRequest my_member_id.
                     * @member {string} my_member_id
                     * @memberof com.glowpush.glendon.topics.UpdateGroupStateRequest
                     * @instance
                     */
                    UpdateGroupStateRequest.prototype.my_member_id = "";

                    /**
                     * UpdateGroupStateRequest group_state.
                     * @member {com.glowpush.glendon.topics.IGroupState|null|undefined} group_state
                     * @memberof com.glowpush.glendon.topics.UpdateGroupStateRequest
                     * @instance
                     */
                    UpdateGroupStateRequest.prototype.group_state = null;

                    return UpdateGroupStateRequest;
                })();

                topics.UpdateGroupStateResponse = (function() {

                    /**
                     * Properties of an UpdateGroupStateResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateGroupStateResponse
                     */

                    /**
                     * Constructs a new UpdateGroupStateResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateGroupStateResponse.
                     * @implements IUpdateGroupStateResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateGroupStateResponse=} [properties] Properties to set
                     */
                    function UpdateGroupStateResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return UpdateGroupStateResponse;
                })();

                topics.FetchStatusRequest = (function() {

                    /**
                     * Properties of a FetchStatusRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchStatusRequest
                     */

                    /**
                     * Constructs a new FetchStatusRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchStatusRequest.
                     * @implements IFetchStatusRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchStatusRequest=} [properties] Properties to set
                     */
                    function FetchStatusRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return FetchStatusRequest;
                })();

                topics.FetchStatusResponse = (function() {

                    /**
                     * Properties of a FetchStatusResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchStatusResponse
                     * @property {com.glowpush.glendon.topics.OperatingMode|null} [operating_mode] FetchStatusResponse operating_mode
                     * @property {com.glowpush.glendon.topics.ConnectionStatus|null} [connection_status] FetchStatusResponse connection_status
                     */

                    /**
                     * Constructs a new FetchStatusResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchStatusResponse.
                     * @implements IFetchStatusResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchStatusResponse=} [properties] Properties to set
                     */
                    function FetchStatusResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FetchStatusResponse operating_mode.
                     * @member {com.glowpush.glendon.topics.OperatingMode} operating_mode
                     * @memberof com.glowpush.glendon.topics.FetchStatusResponse
                     * @instance
                     */
                    FetchStatusResponse.prototype.operating_mode = 0;

                    /**
                     * FetchStatusResponse connection_status.
                     * @member {com.glowpush.glendon.topics.ConnectionStatus} connection_status
                     * @memberof com.glowpush.glendon.topics.FetchStatusResponse
                     * @instance
                     */
                    FetchStatusResponse.prototype.connection_status = 0;

                    return FetchStatusResponse;
                })();

                topics.FetchFirmwareDetailsRequest = (function() {

                    /**
                     * Properties of a FetchFirmwareDetailsRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchFirmwareDetailsRequest
                     */

                    /**
                     * Constructs a new FetchFirmwareDetailsRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchFirmwareDetailsRequest.
                     * @implements IFetchFirmwareDetailsRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchFirmwareDetailsRequest=} [properties] Properties to set
                     */
                    function FetchFirmwareDetailsRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return FetchFirmwareDetailsRequest;
                })();

                topics.FetchFirmwareDetailsResponse = (function() {

                    /**
                     * Properties of a FetchFirmwareDetailsResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchFirmwareDetailsResponse
                     * @property {string|null} [firmware_version] FetchFirmwareDetailsResponse firmware_version
                     * @property {number|null} [device_responded_at] FetchFirmwareDetailsResponse device_responded_at
                     * @property {string|null} [hardware_identifier] FetchFirmwareDetailsResponse hardware_identifier
                     */

                    /**
                     * Constructs a new FetchFirmwareDetailsResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchFirmwareDetailsResponse.
                     * @implements IFetchFirmwareDetailsResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchFirmwareDetailsResponse=} [properties] Properties to set
                     */
                    function FetchFirmwareDetailsResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FetchFirmwareDetailsResponse firmware_version.
                     * @member {string} firmware_version
                     * @memberof com.glowpush.glendon.topics.FetchFirmwareDetailsResponse
                     * @instance
                     */
                    FetchFirmwareDetailsResponse.prototype.firmware_version = "";

                    /**
                     * FetchFirmwareDetailsResponse device_responded_at.
                     * @member {number} device_responded_at
                     * @memberof com.glowpush.glendon.topics.FetchFirmwareDetailsResponse
                     * @instance
                     */
                    FetchFirmwareDetailsResponse.prototype.device_responded_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * FetchFirmwareDetailsResponse hardware_identifier.
                     * @member {string} hardware_identifier
                     * @memberof com.glowpush.glendon.topics.FetchFirmwareDetailsResponse
                     * @instance
                     */
                    FetchFirmwareDetailsResponse.prototype.hardware_identifier = "";

                    return FetchFirmwareDetailsResponse;
                })();

                topics.UpdateOperatingModeRequest = (function() {

                    /**
                     * Properties of an UpdateOperatingModeRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateOperatingModeRequest
                     * @property {com.glowpush.glendon.topics.OperatingMode|null} [next_operating_mode] UpdateOperatingModeRequest next_operating_mode
                     */

                    /**
                     * Constructs a new UpdateOperatingModeRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateOperatingModeRequest.
                     * @implements IUpdateOperatingModeRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateOperatingModeRequest=} [properties] Properties to set
                     */
                    function UpdateOperatingModeRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UpdateOperatingModeRequest next_operating_mode.
                     * @member {com.glowpush.glendon.topics.OperatingMode} next_operating_mode
                     * @memberof com.glowpush.glendon.topics.UpdateOperatingModeRequest
                     * @instance
                     */
                    UpdateOperatingModeRequest.prototype.next_operating_mode = 0;

                    return UpdateOperatingModeRequest;
                })();

                topics.UpdateOperatingModeResponse = (function() {

                    /**
                     * Properties of an UpdateOperatingModeResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateOperatingModeResponse
                     */

                    /**
                     * Constructs a new UpdateOperatingModeResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateOperatingModeResponse.
                     * @implements IUpdateOperatingModeResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateOperatingModeResponse=} [properties] Properties to set
                     */
                    function UpdateOperatingModeResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return UpdateOperatingModeResponse;
                })();

                topics.FetchNetworkConfigRequest = (function() {

                    /**
                     * Properties of a FetchNetworkConfigRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchNetworkConfigRequest
                     */

                    /**
                     * Constructs a new FetchNetworkConfigRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchNetworkConfigRequest.
                     * @implements IFetchNetworkConfigRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchNetworkConfigRequest=} [properties] Properties to set
                     */
                    function FetchNetworkConfigRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return FetchNetworkConfigRequest;
                })();

                topics.FetchNetworkConfigResponse = (function() {

                    /**
                     * Properties of a FetchNetworkConfigResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchNetworkConfigResponse
                     * @property {com.glowpush.glendon.topics.INetworkConfig|null} [network_config] FetchNetworkConfigResponse network_config
                     * @property {com.glowpush.glendon.topics.OperatingMode|null} [next_operating_mode] FetchNetworkConfigResponse next_operating_mode
                     */

                    /**
                     * Constructs a new FetchNetworkConfigResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchNetworkConfigResponse.
                     * @implements IFetchNetworkConfigResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchNetworkConfigResponse=} [properties] Properties to set
                     */
                    function FetchNetworkConfigResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FetchNetworkConfigResponse network_config.
                     * @member {com.glowpush.glendon.topics.INetworkConfig|null|undefined} network_config
                     * @memberof com.glowpush.glendon.topics.FetchNetworkConfigResponse
                     * @instance
                     */
                    FetchNetworkConfigResponse.prototype.network_config = null;

                    /**
                     * FetchNetworkConfigResponse next_operating_mode.
                     * @member {com.glowpush.glendon.topics.OperatingMode} next_operating_mode
                     * @memberof com.glowpush.glendon.topics.FetchNetworkConfigResponse
                     * @instance
                     */
                    FetchNetworkConfigResponse.prototype.next_operating_mode = 0;

                    return FetchNetworkConfigResponse;
                })();

                topics.UpdateNetworkConfigRequest = (function() {

                    /**
                     * Properties of an UpdateNetworkConfigRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateNetworkConfigRequest
                     * @property {com.glowpush.glendon.topics.INetworkConfig|null} [network_config] UpdateNetworkConfigRequest network_config
                     */

                    /**
                     * Constructs a new UpdateNetworkConfigRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateNetworkConfigRequest.
                     * @implements IUpdateNetworkConfigRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateNetworkConfigRequest=} [properties] Properties to set
                     */
                    function UpdateNetworkConfigRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UpdateNetworkConfigRequest network_config.
                     * @member {com.glowpush.glendon.topics.INetworkConfig|null|undefined} network_config
                     * @memberof com.glowpush.glendon.topics.UpdateNetworkConfigRequest
                     * @instance
                     */
                    UpdateNetworkConfigRequest.prototype.network_config = null;

                    return UpdateNetworkConfigRequest;
                })();

                topics.UpdateNetworkConfigResponse = (function() {

                    /**
                     * Properties of an UpdateNetworkConfigResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateNetworkConfigResponse
                     */

                    /**
                     * Constructs a new UpdateNetworkConfigResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateNetworkConfigResponse.
                     * @implements IUpdateNetworkConfigResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateNetworkConfigResponse=} [properties] Properties to set
                     */
                    function UpdateNetworkConfigResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return UpdateNetworkConfigResponse;
                })();

                topics.RebootRequest = (function() {

                    /**
                     * Properties of a RebootRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IRebootRequest
                     */

                    /**
                     * Constructs a new RebootRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a RebootRequest.
                     * @implements IRebootRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IRebootRequest=} [properties] Properties to set
                     */
                    function RebootRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return RebootRequest;
                })();

                topics.RebootResponse = (function() {

                    /**
                     * Properties of a RebootResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IRebootResponse
                     */

                    /**
                     * Constructs a new RebootResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a RebootResponse.
                     * @implements IRebootResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IRebootResponse=} [properties] Properties to set
                     */
                    function RebootResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return RebootResponse;
                })();

                topics.FetchLightsRequest = (function() {

                    /**
                     * Properties of a FetchLightsRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchLightsRequest
                     */

                    /**
                     * Constructs a new FetchLightsRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchLightsRequest.
                     * @implements IFetchLightsRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchLightsRequest=} [properties] Properties to set
                     */
                    function FetchLightsRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return FetchLightsRequest;
                })();

                topics.FetchLightsResponse = (function() {

                    /**
                     * Properties of a FetchLightsResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IFetchLightsResponse
                     * @property {boolean|null} [enable_lights] FetchLightsResponse enable_lights
                     */

                    /**
                     * Constructs a new FetchLightsResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a FetchLightsResponse.
                     * @implements IFetchLightsResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IFetchLightsResponse=} [properties] Properties to set
                     */
                    function FetchLightsResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FetchLightsResponse enable_lights.
                     * @member {boolean} enable_lights
                     * @memberof com.glowpush.glendon.topics.FetchLightsResponse
                     * @instance
                     */
                    FetchLightsResponse.prototype.enable_lights = false;

                    return FetchLightsResponse;
                })();

                topics.UpdateLightsRequest = (function() {

                    /**
                     * Properties of an UpdateLightsRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateLightsRequest
                     * @property {boolean|null} [enable_lights] UpdateLightsRequest enable_lights
                     */

                    /**
                     * Constructs a new UpdateLightsRequest.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateLightsRequest.
                     * @implements IUpdateLightsRequest
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateLightsRequest=} [properties] Properties to set
                     */
                    function UpdateLightsRequest(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UpdateLightsRequest enable_lights.
                     * @member {boolean} enable_lights
                     * @memberof com.glowpush.glendon.topics.UpdateLightsRequest
                     * @instance
                     */
                    UpdateLightsRequest.prototype.enable_lights = false;

                    return UpdateLightsRequest;
                })();

                topics.UpdateLightsResponse = (function() {

                    /**
                     * Properties of an UpdateLightsResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IUpdateLightsResponse
                     */

                    /**
                     * Constructs a new UpdateLightsResponse.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an UpdateLightsResponse.
                     * @implements IUpdateLightsResponse
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IUpdateLightsResponse=} [properties] Properties to set
                     */
                    function UpdateLightsResponse(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return UpdateLightsResponse;
                })();

                /**
                 * OperatingMode enum.
                 * @name com.glowpush.glendon.topics.OperatingMode
                 * @enum {number}
                 * @property {number} OPERATING_MODE_UNSPECIFIED=0 OPERATING_MODE_UNSPECIFIED value
                 * @property {number} OPERATING_MODE_STATION=1 OPERATING_MODE_STATION value
                 * @property {number} OPERATING_MODE_SETUP=2 OPERATING_MODE_SETUP value
                 */
                topics.OperatingMode = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "OPERATING_MODE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "OPERATING_MODE_STATION"] = 1;
                    values[valuesById[2] = "OPERATING_MODE_SETUP"] = 2;
                    return values;
                })();

                /**
                 * ConnectionStatus enum.
                 * @name com.glowpush.glendon.topics.ConnectionStatus
                 * @enum {number}
                 * @property {number} WIFI_STATUS_UNSPECIFIED=0 WIFI_STATUS_UNSPECIFIED value
                 * @property {number} WIFI_STATUS_IDLE=1 WIFI_STATUS_IDLE value
                 * @property {number} WIFI_STATUS_INITIALIZED=2 WIFI_STATUS_INITIALIZED value
                 * @property {number} WIFI_STATUS_DNS_RESOLVING=3 WIFI_STATUS_DNS_RESOLVING value
                 * @property {number} WIFI_STATUS_NTP_RESOLVING=4 WIFI_STATUS_NTP_RESOLVING value
                 * @property {number} WIFI_STATUS_CONNECTING=5 WIFI_STATUS_CONNECTING value
                 * @property {number} WIFI_STATUS_CONNECTED=6 WIFI_STATUS_CONNECTED value
                 */
                topics.ConnectionStatus = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "WIFI_STATUS_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "WIFI_STATUS_IDLE"] = 1;
                    values[valuesById[2] = "WIFI_STATUS_INITIALIZED"] = 2;
                    values[valuesById[3] = "WIFI_STATUS_DNS_RESOLVING"] = 3;
                    values[valuesById[4] = "WIFI_STATUS_NTP_RESOLVING"] = 4;
                    values[valuesById[5] = "WIFI_STATUS_CONNECTING"] = 5;
                    values[valuesById[6] = "WIFI_STATUS_CONNECTED"] = 6;
                    return values;
                })();

                topics.NetworkConfig = (function() {

                    /**
                     * Properties of a NetworkConfig.
                     * @memberof com.glowpush.glendon.topics
                     * @interface INetworkConfig
                     * @property {string|null} [station_ssid] NetworkConfig station_ssid
                     * @property {string|null} [station_password] NetworkConfig station_password
                     * @property {string|null} [mqtt_uri] NetworkConfig mqtt_uri
                     * @property {boolean|null} [enable_mqtt] NetworkConfig enable_mqtt
                     * @property {string|null} [ntp_uri] NetworkConfig ntp_uri
                     * @property {boolean|null} [enable_ntp] NetworkConfig enable_ntp
                     * @property {boolean|null} [enable_http_in_station_mode] NetworkConfig enable_http_in_station_mode
                     */

                    /**
                     * Constructs a new NetworkConfig.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a NetworkConfig.
                     * @implements INetworkConfig
                     * @constructor
                     * @param {com.glowpush.glendon.topics.INetworkConfig=} [properties] Properties to set
                     */
                    function NetworkConfig(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NetworkConfig station_ssid.
                     * @member {string} station_ssid
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.station_ssid = "";

                    /**
                     * NetworkConfig station_password.
                     * @member {string} station_password
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.station_password = "";

                    /**
                     * NetworkConfig mqtt_uri.
                     * @member {string} mqtt_uri
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.mqtt_uri = "";

                    /**
                     * NetworkConfig enable_mqtt.
                     * @member {boolean} enable_mqtt
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.enable_mqtt = false;

                    /**
                     * NetworkConfig ntp_uri.
                     * @member {string} ntp_uri
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.ntp_uri = "";

                    /**
                     * NetworkConfig enable_ntp.
                     * @member {boolean} enable_ntp
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.enable_ntp = false;

                    /**
                     * NetworkConfig enable_http_in_station_mode.
                     * @member {boolean} enable_http_in_station_mode
                     * @memberof com.glowpush.glendon.topics.NetworkConfig
                     * @instance
                     */
                    NetworkConfig.prototype.enable_http_in_station_mode = false;

                    return NetworkConfig;
                })();

                topics.GroupState = (function() {

                    /**
                     * Properties of a GroupState.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IGroupState
                     * @property {number|null} [captured_at] GroupState captured_at
                     * @property {Array.<com.glowpush.glendon.topics.GroupState.IMember>|null} [group_members] GroupState group_members
                     */

                    /**
                     * Constructs a new GroupState.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a GroupState.
                     * @implements IGroupState
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IGroupState=} [properties] Properties to set
                     */
                    function GroupState(properties) {
                        this.group_members = [];
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GroupState captured_at.
                     * @member {number} captured_at
                     * @memberof com.glowpush.glendon.topics.GroupState
                     * @instance
                     */
                    GroupState.prototype.captured_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * GroupState group_members.
                     * @member {Array.<com.glowpush.glendon.topics.GroupState.IMember>} group_members
                     * @memberof com.glowpush.glendon.topics.GroupState
                     * @instance
                     */
                    GroupState.prototype.group_members = $util.emptyArray;

                    GroupState.Member = (function() {

                        /**
                         * Properties of a Member.
                         * @memberof com.glowpush.glendon.topics.GroupState
                         * @interface IMember
                         * @property {string|null} [member_id] Member member_id
                         * @property {com.glowpush.glendon.topics.IColor|null} [color] Member color
                         * @property {number|null} [last_active_at] Member last_active_at
                         */

                        /**
                         * Constructs a new Member.
                         * @memberof com.glowpush.glendon.topics.GroupState
                         * @classdesc Represents a Member.
                         * @implements IMember
                         * @constructor
                         * @param {com.glowpush.glendon.topics.GroupState.IMember=} [properties] Properties to set
                         */
                        function Member(properties) {
                            if (properties)
                                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Member member_id.
                         * @member {string} member_id
                         * @memberof com.glowpush.glendon.topics.GroupState.Member
                         * @instance
                         */
                        Member.prototype.member_id = "";

                        /**
                         * Member color.
                         * @member {com.glowpush.glendon.topics.IColor|null|undefined} color
                         * @memberof com.glowpush.glendon.topics.GroupState.Member
                         * @instance
                         */
                        Member.prototype.color = null;

                        /**
                         * Member last_active_at.
                         * @member {number} last_active_at
                         * @memberof com.glowpush.glendon.topics.GroupState.Member
                         * @instance
                         */
                        Member.prototype.last_active_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        return Member;
                    })();

                    return GroupState;
                })();

                topics.Color = (function() {

                    /**
                     * Properties of a Color.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IColor
                     * @property {number|null} [red] Color red
                     * @property {number|null} [green] Color green
                     * @property {number|null} [blue] Color blue
                     */

                    /**
                     * Constructs a new Color.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a Color.
                     * @implements IColor
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IColor=} [properties] Properties to set
                     */
                    function Color(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Color red.
                     * @member {number} red
                     * @memberof com.glowpush.glendon.topics.Color
                     * @instance
                     */
                    Color.prototype.red = 0;

                    /**
                     * Color green.
                     * @member {number} green
                     * @memberof com.glowpush.glendon.topics.Color
                     * @instance
                     */
                    Color.prototype.green = 0;

                    /**
                     * Color blue.
                     * @member {number} blue
                     * @memberof com.glowpush.glendon.topics.Color
                     * @instance
                     */
                    Color.prototype.blue = 0;

                    return Color;
                })();

                /**
                 * Event enum.
                 * @name com.glowpush.glendon.topics.Event
                 * @enum {number}
                 * @property {number} EVENT_UNSPECIFIED=0 EVENT_UNSPECIFIED value
                 * @property {number} MQTT_SESSION_STARTED=1 MQTT_SESSION_STARTED value
                 * @property {number} MQTT_SESSION_ENDED=2 MQTT_SESSION_ENDED value
                 * @property {number} BUTTON_PRESSED=3 BUTTON_PRESSED value
                 * @property {number} STATUS_UPDATED=4 STATUS_UPDATED value
                 * @property {number} DEVICE_ACTIVATED=5 DEVICE_ACTIVATED value
                 */
                topics.Event = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "EVENT_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "MQTT_SESSION_STARTED"] = 1;
                    values[valuesById[2] = "MQTT_SESSION_ENDED"] = 2;
                    values[valuesById[3] = "BUTTON_PRESSED"] = 3;
                    values[valuesById[4] = "STATUS_UPDATED"] = 4;
                    values[valuesById[5] = "DEVICE_ACTIVATED"] = 5;
                    return values;
                })();

                /**
                 * SessionBootType enum.
                 * @name com.glowpush.glendon.topics.SessionBootType
                 * @enum {number}
                 * @property {number} SESSION_BOOT_TYPE_UNSPECIFIED=0 SESSION_BOOT_TYPE_UNSPECIFIED value
                 * @property {number} SESSION_BOOT_TYPE_NORMAL=1 SESSION_BOOT_TYPE_NORMAL value
                 * @property {number} SESSION_BOOT_TYPE_ULTRA_LOW_POWER=2 SESSION_BOOT_TYPE_ULTRA_LOW_POWER value
                 */
                topics.SessionBootType = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SESSION_BOOT_TYPE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "SESSION_BOOT_TYPE_NORMAL"] = 1;
                    values[valuesById[2] = "SESSION_BOOT_TYPE_ULTRA_LOW_POWER"] = 2;
                    return values;
                })();

                topics.MqttSessionStarted = (function() {

                    /**
                     * Properties of a MqttSessionStarted.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IMqttSessionStarted
                     * @property {string|null} [hardware_identifier] MqttSessionStarted hardware_identifier
                     * @property {string|null} [firmware_version] MqttSessionStarted firmware_version
                     * @property {string|null} [session_id] MqttSessionStarted session_id
                     * @property {number|null} [init_pre_wifi_s] MqttSessionStarted init_pre_wifi_s
                     * @property {number|null} [init_wifi_connect_s] MqttSessionStarted init_wifi_connect_s
                     * @property {number|null} [init_dhcpc_s] MqttSessionStarted init_dhcpc_s
                     * @property {number|null} [init_ntp_s] MqttSessionStarted init_ntp_s
                     * @property {number|null} [init_mqtt_connect_s] MqttSessionStarted init_mqtt_connect_s
                     * @property {com.glowpush.glendon.topics.SessionBootType|null} [boot_type] MqttSessionStarted boot_type
                     * @property {number|null} [temperature_c] MqttSessionStarted temperature_c
                     * @property {number|null} [rssi_dbm] MqttSessionStarted rssi_dbm
                     */

                    /**
                     * Constructs a new MqttSessionStarted.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a MqttSessionStarted.
                     * @implements IMqttSessionStarted
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IMqttSessionStarted=} [properties] Properties to set
                     */
                    function MqttSessionStarted(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MqttSessionStarted hardware_identifier.
                     * @member {string} hardware_identifier
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.hardware_identifier = "";

                    /**
                     * MqttSessionStarted firmware_version.
                     * @member {string} firmware_version
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.firmware_version = "";

                    /**
                     * MqttSessionStarted session_id.
                     * @member {string} session_id
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.session_id = "";

                    /**
                     * MqttSessionStarted init_pre_wifi_s.
                     * @member {number} init_pre_wifi_s
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.init_pre_wifi_s = 0;

                    /**
                     * MqttSessionStarted init_wifi_connect_s.
                     * @member {number} init_wifi_connect_s
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.init_wifi_connect_s = 0;

                    /**
                     * MqttSessionStarted init_dhcpc_s.
                     * @member {number} init_dhcpc_s
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.init_dhcpc_s = 0;

                    /**
                     * MqttSessionStarted init_ntp_s.
                     * @member {number} init_ntp_s
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.init_ntp_s = 0;

                    /**
                     * MqttSessionStarted init_mqtt_connect_s.
                     * @member {number} init_mqtt_connect_s
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.init_mqtt_connect_s = 0;

                    /**
                     * MqttSessionStarted boot_type.
                     * @member {com.glowpush.glendon.topics.SessionBootType} boot_type
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.boot_type = 0;

                    /**
                     * MqttSessionStarted temperature_c.
                     * @member {number} temperature_c
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.temperature_c = 0;

                    /**
                     * MqttSessionStarted rssi_dbm.
                     * @member {number} rssi_dbm
                     * @memberof com.glowpush.glendon.topics.MqttSessionStarted
                     * @instance
                     */
                    MqttSessionStarted.prototype.rssi_dbm = 0;

                    return MqttSessionStarted;
                })();

                topics.MqttSessionEnded = (function() {

                    /**
                     * Properties of a MqttSessionEnded.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IMqttSessionEnded
                     * @property {string|null} [hardware_identifier] MqttSessionEnded hardware_identifier
                     * @property {string|null} [session_id] MqttSessionEnded session_id
                     */

                    /**
                     * Constructs a new MqttSessionEnded.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a MqttSessionEnded.
                     * @implements IMqttSessionEnded
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IMqttSessionEnded=} [properties] Properties to set
                     */
                    function MqttSessionEnded(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MqttSessionEnded hardware_identifier.
                     * @member {string} hardware_identifier
                     * @memberof com.glowpush.glendon.topics.MqttSessionEnded
                     * @instance
                     */
                    MqttSessionEnded.prototype.hardware_identifier = "";

                    /**
                     * MqttSessionEnded session_id.
                     * @member {string} session_id
                     * @memberof com.glowpush.glendon.topics.MqttSessionEnded
                     * @instance
                     */
                    MqttSessionEnded.prototype.session_id = "";

                    return MqttSessionEnded;
                })();

                topics.ButtonPressed = (function() {

                    /**
                     * Properties of a ButtonPressed.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IButtonPressed
                     * @property {number|null} [button_code] ButtonPressed button_code
                     */

                    /**
                     * Constructs a new ButtonPressed.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a ButtonPressed.
                     * @implements IButtonPressed
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IButtonPressed=} [properties] Properties to set
                     */
                    function ButtonPressed(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ButtonPressed button_code.
                     * @member {number} button_code
                     * @memberof com.glowpush.glendon.topics.ButtonPressed
                     * @instance
                     */
                    ButtonPressed.prototype.button_code = 0;

                    return ButtonPressed;
                })();

                topics.StatusUpdated = (function() {

                    /**
                     * Properties of a StatusUpdated.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IStatusUpdated
                     * @property {number|null} [temperature_c] StatusUpdated temperature_c
                     * @property {number|null} [rssi_dbm] StatusUpdated rssi_dbm
                     */

                    /**
                     * Constructs a new StatusUpdated.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a StatusUpdated.
                     * @implements IStatusUpdated
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IStatusUpdated=} [properties] Properties to set
                     */
                    function StatusUpdated(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StatusUpdated temperature_c.
                     * @member {number} temperature_c
                     * @memberof com.glowpush.glendon.topics.StatusUpdated
                     * @instance
                     */
                    StatusUpdated.prototype.temperature_c = 0;

                    /**
                     * StatusUpdated rssi_dbm.
                     * @member {number} rssi_dbm
                     * @memberof com.glowpush.glendon.topics.StatusUpdated
                     * @instance
                     */
                    StatusUpdated.prototype.rssi_dbm = 0;

                    return StatusUpdated;
                })();

                topics.DeviceActivated = (function() {

                    /**
                     * Properties of a DeviceActivated.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IDeviceActivated
                     */

                    /**
                     * Constructs a new DeviceActivated.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a DeviceActivated.
                     * @implements IDeviceActivated
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IDeviceActivated=} [properties] Properties to set
                     */
                    function DeviceActivated(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    return DeviceActivated;
                })();

                topics.DirectiveRequestPayload = (function() {

                    /**
                     * Properties of a DirectiveRequestPayload.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IDirectiveRequestPayload
                     * @property {string|null} [request_id] DirectiveRequestPayload request_id
                     * @property {com.glowpush.glendon.topics.Directive|null} [directive] DirectiveRequestPayload directive
                     * @property {Uint8Array|null} [request] DirectiveRequestPayload request
                     */

                    /**
                     * Constructs a new DirectiveRequestPayload.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a DirectiveRequestPayload.
                     * @implements IDirectiveRequestPayload
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IDirectiveRequestPayload=} [properties] Properties to set
                     */
                    function DirectiveRequestPayload(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DirectiveRequestPayload request_id.
                     * @member {string} request_id
                     * @memberof com.glowpush.glendon.topics.DirectiveRequestPayload
                     * @instance
                     */
                    DirectiveRequestPayload.prototype.request_id = "";

                    /**
                     * DirectiveRequestPayload directive.
                     * @member {com.glowpush.glendon.topics.Directive} directive
                     * @memberof com.glowpush.glendon.topics.DirectiveRequestPayload
                     * @instance
                     */
                    DirectiveRequestPayload.prototype.directive = 0;

                    /**
                     * DirectiveRequestPayload request.
                     * @member {Uint8Array} request
                     * @memberof com.glowpush.glendon.topics.DirectiveRequestPayload
                     * @instance
                     */
                    DirectiveRequestPayload.prototype.request = $util.newBuffer([]);

                    return DirectiveRequestPayload;
                })();

                topics.DirectiveResponsePayload = (function() {

                    /**
                     * Properties of a DirectiveResponsePayload.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IDirectiveResponsePayload
                     * @property {string|null} [request_id] DirectiveResponsePayload request_id
                     * @property {com.glowpush.glendon.topics.Directive|null} [directive] DirectiveResponsePayload directive
                     * @property {com.glowpush.glendon.topics.StatusCode|null} [status_code] DirectiveResponsePayload status_code
                     * @property {Uint8Array|null} [response] DirectiveResponsePayload response
                     * @property {number|null} [execution_ms] DirectiveResponsePayload execution_ms
                     * @property {number|null} [device_responded_at] DirectiveResponsePayload device_responded_at
                     */

                    /**
                     * Constructs a new DirectiveResponsePayload.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a DirectiveResponsePayload.
                     * @implements IDirectiveResponsePayload
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IDirectiveResponsePayload=} [properties] Properties to set
                     */
                    function DirectiveResponsePayload(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DirectiveResponsePayload request_id.
                     * @member {string} request_id
                     * @memberof com.glowpush.glendon.topics.DirectiveResponsePayload
                     * @instance
                     */
                    DirectiveResponsePayload.prototype.request_id = "";

                    /**
                     * DirectiveResponsePayload directive.
                     * @member {com.glowpush.glendon.topics.Directive} directive
                     * @memberof com.glowpush.glendon.topics.DirectiveResponsePayload
                     * @instance
                     */
                    DirectiveResponsePayload.prototype.directive = 0;

                    /**
                     * DirectiveResponsePayload status_code.
                     * @member {com.glowpush.glendon.topics.StatusCode} status_code
                     * @memberof com.glowpush.glendon.topics.DirectiveResponsePayload
                     * @instance
                     */
                    DirectiveResponsePayload.prototype.status_code = 0;

                    /**
                     * DirectiveResponsePayload response.
                     * @member {Uint8Array} response
                     * @memberof com.glowpush.glendon.topics.DirectiveResponsePayload
                     * @instance
                     */
                    DirectiveResponsePayload.prototype.response = $util.newBuffer([]);

                    /**
                     * DirectiveResponsePayload execution_ms.
                     * @member {number} execution_ms
                     * @memberof com.glowpush.glendon.topics.DirectiveResponsePayload
                     * @instance
                     */
                    DirectiveResponsePayload.prototype.execution_ms = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * DirectiveResponsePayload device_responded_at.
                     * @member {number} device_responded_at
                     * @memberof com.glowpush.glendon.topics.DirectiveResponsePayload
                     * @instance
                     */
                    DirectiveResponsePayload.prototype.device_responded_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    return DirectiveResponsePayload;
                })();

                topics.EventPayload = (function() {

                    /**
                     * Properties of an EventPayload.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IEventPayload
                     * @property {string|null} [event_id] EventPayload event_id
                     * @property {com.glowpush.glendon.topics.Event|null} [event] EventPayload event
                     * @property {Uint8Array|null} [info] EventPayload info
                     * @property {number|null} [device_emitted_at] EventPayload device_emitted_at
                     */

                    /**
                     * Constructs a new EventPayload.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an EventPayload.
                     * @implements IEventPayload
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IEventPayload=} [properties] Properties to set
                     */
                    function EventPayload(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EventPayload event_id.
                     * @member {string} event_id
                     * @memberof com.glowpush.glendon.topics.EventPayload
                     * @instance
                     */
                    EventPayload.prototype.event_id = "";

                    /**
                     * EventPayload event.
                     * @member {com.glowpush.glendon.topics.Event} event
                     * @memberof com.glowpush.glendon.topics.EventPayload
                     * @instance
                     */
                    EventPayload.prototype.event = 0;

                    /**
                     * EventPayload info.
                     * @member {Uint8Array} info
                     * @memberof com.glowpush.glendon.topics.EventPayload
                     * @instance
                     */
                    EventPayload.prototype.info = $util.newBuffer([]);

                    /**
                     * EventPayload device_emitted_at.
                     * @member {number} device_emitted_at
                     * @memberof com.glowpush.glendon.topics.EventPayload
                     * @instance
                     */
                    EventPayload.prototype.device_emitted_at = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    return EventPayload;
                })();

                /**
                 * StatusCode enum.
                 * @name com.glowpush.glendon.topics.StatusCode
                 * @enum {number}
                 * @property {number} OK=0 OK value
                 * @property {number} CANCELLED=1 CANCELLED value
                 * @property {number} UNKNOWN=2 UNKNOWN value
                 * @property {number} INVALID_ARGUMENT=3 INVALID_ARGUMENT value
                 * @property {number} DEADLINE_EXCEEDED=4 DEADLINE_EXCEEDED value
                 * @property {number} NOT_FOUND=5 NOT_FOUND value
                 * @property {number} ALREADY_EXISTS=6 ALREADY_EXISTS value
                 * @property {number} PERMISSION_DENIED=7 PERMISSION_DENIED value
                 * @property {number} UNAUTHENTICATED=16 UNAUTHENTICATED value
                 * @property {number} RESOURCE_EXHAUSTED=8 RESOURCE_EXHAUSTED value
                 * @property {number} FAILED_PRECONDITION=9 FAILED_PRECONDITION value
                 * @property {number} ABORTED=10 ABORTED value
                 * @property {number} OUT_OF_RANGE=11 OUT_OF_RANGE value
                 * @property {number} UNIMPLEMENTED=12 UNIMPLEMENTED value
                 * @property {number} INTERNAL=13 INTERNAL value
                 * @property {number} UNAVAILABLE=14 UNAVAILABLE value
                 * @property {number} DATA_LOSS=15 DATA_LOSS value
                 */
                topics.StatusCode = (function() {
                    const valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "OK"] = 0;
                    values[valuesById[1] = "CANCELLED"] = 1;
                    values[valuesById[2] = "UNKNOWN"] = 2;
                    values[valuesById[3] = "INVALID_ARGUMENT"] = 3;
                    values[valuesById[4] = "DEADLINE_EXCEEDED"] = 4;
                    values[valuesById[5] = "NOT_FOUND"] = 5;
                    values[valuesById[6] = "ALREADY_EXISTS"] = 6;
                    values[valuesById[7] = "PERMISSION_DENIED"] = 7;
                    values[valuesById[16] = "UNAUTHENTICATED"] = 16;
                    values[valuesById[8] = "RESOURCE_EXHAUSTED"] = 8;
                    values[valuesById[9] = "FAILED_PRECONDITION"] = 9;
                    values[valuesById[10] = "ABORTED"] = 10;
                    values[valuesById[11] = "OUT_OF_RANGE"] = 11;
                    values[valuesById[12] = "UNIMPLEMENTED"] = 12;
                    values[valuesById[13] = "INTERNAL"] = 13;
                    values[valuesById[14] = "UNAVAILABLE"] = 14;
                    values[valuesById[15] = "DATA_LOSS"] = 15;
                    return values;
                })();

                topics.EventTopic = (function() {

                    /**
                     * Properties of an EventTopic.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IEventTopic
                     * @property {string|null} [path_template] EventTopic path_template
                     * @property {string|null} [payload] EventTopic payload
                     */

                    /**
                     * Constructs a new EventTopic.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents an EventTopic.
                     * @implements IEventTopic
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IEventTopic=} [properties] Properties to set
                     */
                    function EventTopic(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EventTopic path_template.
                     * @member {string} path_template
                     * @memberof com.glowpush.glendon.topics.EventTopic
                     * @instance
                     */
                    EventTopic.prototype.path_template = "";

                    /**
                     * EventTopic payload.
                     * @member {string} payload
                     * @memberof com.glowpush.glendon.topics.EventTopic
                     * @instance
                     */
                    EventTopic.prototype.payload = "";

                    return EventTopic;
                })();

                topics.DirectiveTopic = (function() {

                    /**
                     * Properties of a DirectiveTopic.
                     * @memberof com.glowpush.glendon.topics
                     * @interface IDirectiveTopic
                     * @property {string|null} [req_path_template] DirectiveTopic req_path_template
                     * @property {string|null} [res_path_template] DirectiveTopic res_path_template
                     * @property {string|null} [req_payload] DirectiveTopic req_payload
                     * @property {string|null} [res_payload] DirectiveTopic res_payload
                     */

                    /**
                     * Constructs a new DirectiveTopic.
                     * @memberof com.glowpush.glendon.topics
                     * @classdesc Represents a DirectiveTopic.
                     * @implements IDirectiveTopic
                     * @constructor
                     * @param {com.glowpush.glendon.topics.IDirectiveTopic=} [properties] Properties to set
                     */
                    function DirectiveTopic(properties) {
                        if (properties)
                            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DirectiveTopic req_path_template.
                     * @member {string} req_path_template
                     * @memberof com.glowpush.glendon.topics.DirectiveTopic
                     * @instance
                     */
                    DirectiveTopic.prototype.req_path_template = "";

                    /**
                     * DirectiveTopic res_path_template.
                     * @member {string} res_path_template
                     * @memberof com.glowpush.glendon.topics.DirectiveTopic
                     * @instance
                     */
                    DirectiveTopic.prototype.res_path_template = "";

                    /**
                     * DirectiveTopic req_payload.
                     * @member {string} req_payload
                     * @memberof com.glowpush.glendon.topics.DirectiveTopic
                     * @instance
                     */
                    DirectiveTopic.prototype.req_payload = "";

                    /**
                     * DirectiveTopic res_payload.
                     * @member {string} res_payload
                     * @memberof com.glowpush.glendon.topics.DirectiveTopic
                     * @instance
                     */
                    DirectiveTopic.prototype.res_payload = "";

                    return DirectiveTopic;
                })();

                return topics;
            })();

            return glendon;
        })();

        return glowpush;
    })();

    return com;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [public_dependency] FileDescriptorProto public_dependency
             * @property {Array.<number>|null} [weak_dependency] FileDescriptorProto weak_dependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [message_type] FileDescriptorProto message_type
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enum_type] FileDescriptorProto enum_type
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [source_code_info] FileDescriptorProto source_code_info
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.public_dependency = [];
                this.weak_dependency = [];
                this.message_type = [];
                this.enum_type = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto public_dependency.
             * @member {Array.<number>} public_dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.public_dependency = $util.emptyArray;

            /**
             * FileDescriptorProto weak_dependency.
             * @member {Array.<number>} weak_dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weak_dependency = $util.emptyArray;

            /**
             * FileDescriptorProto message_type.
             * @member {Array.<google.protobuf.IDescriptorProto>} message_type
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.message_type = $util.emptyArray;

            /**
             * FileDescriptorProto enum_type.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enum_type
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enum_type = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto source_code_info.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} source_code_info
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.source_code_info = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nested_type] DescriptorProto nested_type
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enum_type] DescriptorProto enum_type
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extension_range] DescriptorProto extension_range
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneof_decl] DescriptorProto oneof_decl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reserved_range] DescriptorProto reserved_range
             * @property {Array.<string>|null} [reserved_name] DescriptorProto reserved_name
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nested_type = [];
                this.enum_type = [];
                this.extension_range = [];
                this.oneof_decl = [];
                this.reserved_range = [];
                this.reserved_name = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nested_type.
             * @member {Array.<google.protobuf.IDescriptorProto>} nested_type
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nested_type = $util.emptyArray;

            /**
             * DescriptorProto enum_type.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enum_type
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enum_type = $util.emptyArray;

            /**
             * DescriptorProto extension_range.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extension_range
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension_range = $util.emptyArray;

            /**
             * DescriptorProto oneof_decl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneof_decl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneof_decl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reserved_range.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reserved_range
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reserved_range = $util.emptyArray;

            /**
             * DescriptorProto reserved_name.
             * @member {Array.<string>} reserved_name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reserved_name = $util.emptyArray;

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [type_name] FieldDescriptorProto type_name
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [default_value] FieldDescriptorProto default_value
             * @property {number|null} [oneof_index] FieldDescriptorProto oneof_index
             * @property {string|null} [json_name] FieldDescriptorProto json_name
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto type_name.
             * @member {string} type_name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type_name = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto default_value.
             * @member {string} default_value
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.default_value = "";

            /**
             * FieldDescriptorProto oneof_index.
             * @member {number} oneof_index
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneof_index = 0;

            /**
             * FieldDescriptorProto json_name.
             * @member {string} json_name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.json_name = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [input_type] MethodDescriptorProto input_type
             * @property {string|null} [output_type] MethodDescriptorProto output_type
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [client_streaming] MethodDescriptorProto client_streaming
             * @property {boolean|null} [server_streaming] MethodDescriptorProto server_streaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto input_type.
             * @member {string} input_type
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.input_type = "";

            /**
             * MethodDescriptorProto output_type.
             * @member {string} output_type
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.output_type = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto client_streaming.
             * @member {boolean} client_streaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.client_streaming = false;

            /**
             * MethodDescriptorProto server_streaming.
             * @member {boolean} server_streaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.server_streaming = false;

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [java_package] FileOptions java_package
             * @property {string|null} [java_outer_classname] FileOptions java_outer_classname
             * @property {boolean|null} [java_multiple_files] FileOptions java_multiple_files
             * @property {boolean|null} [java_generate_equals_and_hash] FileOptions java_generate_equals_and_hash
             * @property {boolean|null} [java_string_check_utf8] FileOptions java_string_check_utf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimize_for] FileOptions optimize_for
             * @property {string|null} [go_package] FileOptions go_package
             * @property {boolean|null} [cc_generic_services] FileOptions cc_generic_services
             * @property {boolean|null} [java_generic_services] FileOptions java_generic_services
             * @property {boolean|null} [py_generic_services] FileOptions py_generic_services
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [cc_enable_arenas] FileOptions cc_enable_arenas
             * @property {string|null} [objc_class_prefix] FileOptions objc_class_prefix
             * @property {string|null} [csharp_namespace] FileOptions csharp_namespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] FileOptions uninterpreted_option
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions java_package.
             * @member {string} java_package
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.java_package = "";

            /**
             * FileOptions java_outer_classname.
             * @member {string} java_outer_classname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.java_outer_classname = "";

            /**
             * FileOptions java_multiple_files.
             * @member {boolean} java_multiple_files
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.java_multiple_files = false;

            /**
             * FileOptions java_generate_equals_and_hash.
             * @member {boolean} java_generate_equals_and_hash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.java_generate_equals_and_hash = false;

            /**
             * FileOptions java_string_check_utf8.
             * @member {boolean} java_string_check_utf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.java_string_check_utf8 = false;

            /**
             * FileOptions optimize_for.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimize_for
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimize_for = 1;

            /**
             * FileOptions go_package.
             * @member {string} go_package
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.go_package = "";

            /**
             * FileOptions cc_generic_services.
             * @member {boolean} cc_generic_services
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.cc_generic_services = false;

            /**
             * FileOptions java_generic_services.
             * @member {boolean} java_generic_services
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.java_generic_services = false;

            /**
             * FileOptions py_generic_services.
             * @member {boolean} py_generic_services
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.py_generic_services = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions cc_enable_arenas.
             * @member {boolean} cc_enable_arenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.cc_enable_arenas = false;

            /**
             * FileOptions objc_class_prefix.
             * @member {string} objc_class_prefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objc_class_prefix = "";

            /**
             * FileOptions csharp_namespace.
             * @member {string} csharp_namespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharp_namespace = "";

            /**
             * FileOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpreted_option = $util.emptyArray;

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [message_set_wire_format] MessageOptions message_set_wire_format
             * @property {boolean|null} [no_standard_descriptor_accessor] MessageOptions no_standard_descriptor_accessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [map_entry] MessageOptions map_entry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] MessageOptions uninterpreted_option
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions message_set_wire_format.
             * @member {boolean} message_set_wire_format
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.message_set_wire_format = false;

            /**
             * MessageOptions no_standard_descriptor_accessor.
             * @member {boolean} no_standard_descriptor_accessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.no_standard_descriptor_accessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions map_entry.
             * @member {boolean} map_entry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.map_entry = false;

            /**
             * MessageOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpreted_option = $util.emptyArray;

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] FieldOptions uninterpreted_option
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpreted_option = $util.emptyArray;

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] OneofOptions uninterpreted_option
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpreted_option = $util.emptyArray;

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allow_alias] EnumOptions allow_alias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] EnumOptions uninterpreted_option
             * @property {com.glowpush.glendon.topics.IDirectiveTopic|null} [".com.glowpush.glendon.topics.directive_topic"] EnumOptions .com.glowpush.glendon.topics.directive_topic
             * @property {com.glowpush.glendon.topics.IEventTopic|null} [".com.glowpush.glendon.topics.event_topic"] EnumOptions .com.glowpush.glendon.topics.event_topic
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allow_alias.
             * @member {boolean} allow_alias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allow_alias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpreted_option = $util.emptyArray;

            /**
             * EnumOptions .com.glowpush.glendon.topics.directive_topic.
             * @member {com.glowpush.glendon.topics.IDirectiveTopic|null|undefined} .com.glowpush.glendon.topics.directive_topic
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype[".com.glowpush.glendon.topics.directive_topic"] = null;

            /**
             * EnumOptions .com.glowpush.glendon.topics.event_topic.
             * @member {com.glowpush.glendon.topics.IEventTopic|null|undefined} .com.glowpush.glendon.topics.event_topic
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype[".com.glowpush.glendon.topics.event_topic"] = null;

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] EnumValueOptions uninterpreted_option
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpreted_option = $util.emptyArray;

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] ServiceOptions uninterpreted_option
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpreted_option = $util.emptyArray;

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpreted_option] MethodOptions uninterpreted_option
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpreted_option = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpreted_option.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpreted_option
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpreted_option = $util.emptyArray;

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifier_value] UninterpretedOption identifier_value
             * @property {number|null} [positive_int_value] UninterpretedOption positive_int_value
             * @property {number|null} [negative_int_value] UninterpretedOption negative_int_value
             * @property {number|null} [double_value] UninterpretedOption double_value
             * @property {Uint8Array|null} [string_value] UninterpretedOption string_value
             * @property {string|null} [aggregate_value] UninterpretedOption aggregate_value
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifier_value.
             * @member {string} identifier_value
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifier_value = "";

            /**
             * UninterpretedOption positive_int_value.
             * @member {number} positive_int_value
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positive_int_value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negative_int_value.
             * @member {number} negative_int_value
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negative_int_value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption double_value.
             * @member {number} double_value
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.double_value = 0;

            /**
             * UninterpretedOption string_value.
             * @member {Uint8Array} string_value
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.string_value = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregate_value.
             * @member {string} aggregate_value
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregate_value = "";

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} name_part NamePart name_part
                 * @property {boolean} is_extension NamePart is_extension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart name_part.
                 * @member {string} name_part
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.name_part = "";

                /**
                 * NamePart is_extension.
                 * @member {boolean} is_extension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.is_extension = false;

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leading_comments] Location leading_comments
                 * @property {string|null} [trailing_comments] Location trailing_comments
                 * @property {Array.<string>|null} [leading_detached_comments] Location leading_detached_comments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leading_detached_comments = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leading_comments.
                 * @member {string} leading_comments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leading_comments = "";

                /**
                 * Location trailing_comments.
                 * @member {string} trailing_comments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailing_comments = "";

                /**
                 * Location leading_detached_comments.
                 * @member {Array.<string>} leading_detached_comments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leading_detached_comments = $util.emptyArray;

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [source_file] Annotation source_file
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation source_file.
                 * @member {string} source_file
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.source_file = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
