import { useState } from "react";
import { Api, topics, useApi } from "./proto";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  MdAdd,
  MdDelete,
  MdRemove,
  MdSend,
  MdSignalWifi4Bar,
  MdSignalWifiOff,
  MdUpdate,
} from "react-icons/md";
import _ from "lodash";
import { IndexLinkContainer } from "react-router-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import { SketchPicker } from "react-color";
import random_color from "randomcolor";
import { FaRandom } from "react-icons/fa";
import moment from "moment";

export function Groups() {
  let { is_loading, is_failure, response, reload } = useApi.listGroups({
    page_size: 100, // TODO render paging UI
  });
  if (is_loading) {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }
  if (is_failure) {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <Alert variant="danger">Failed to load groups list.</Alert>
          </Col>
        </Row>
      </Container>
    );
  }
  let { total_size, groups } = response;
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h3>Groups ({total_size} total)</h3>
          <ListGroup>
            {groups.map(({ devices, group_id, group_name, created_at }) => (
              <IndexLinkContainer key={group_id} to={`/group/${group_id}`}>
                <ListGroupItem action>
                  {group_name}
                  <span className="ml-2">
                    {devices.map(({ device_id, group_device_color }) => (
                      <Badge key={device_id} className="mr-2" variant="info">
                        {device_id.slice(0, 2)}..{device_id.slice(-2)}
                      </Badge>
                    ))}
                  </span>
                  <span className="text-muted float-right">
                    {moment(created_at).fromNow()}
                  </span>
                </ListGroupItem>
              </IndexLinkContainer>
            ))}
          </ListGroup>
          <AddGroupForm onAdded={reload} />
        </Col>
      </Row>
    </Container>
  );
}

function AddGroupForm({ onAdded }) {
  let [mode, set_mode] = useState("closed");
  let [group_name, set_group_name] = useState("");
  if (mode === "closed") {
    return (
      <Form className="mt-3 w-25">
        <Button onClick={() => set_mode("assembling")}>
          <MdAdd />
        </Button>
      </Form>
    );
  }
  let is_creating = mode === "creating";
  let create_it = () => {
    if (group_name === "") {
      return;
    }
    set_mode("creating");
    Api.createGroup({ group_name })
      .then(() => {
        set_mode("closed");
        set_group_name("");
        onAdded();
      })
      .catch((err) => {
        console.log("failed to create", err);
        set_mode("assembling");
      });
  };
  return (
    <Form
      className="mt-3 w-25"
      onSubmit={(e) => {
        e && e.preventDefault();
        create_it();
      }}
    >
      <Form.Group controlId="new_group_name" size="sm">
        <Form.Control
          onChange={(event) => set_group_name(event.target.value)}
          value={group_name}
          disabled={is_creating}
          type="text"
          autoFocus
          placeholder="My Group Name"
        />
        <p className="mt-3">
          <Button
            className="mr-2"
            variant="secondary"
            disabled={is_creating}
            onClick={() => {
              set_mode("closed");
              set_group_name("");
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={is_creating || group_name === ""}
            onClick={() => create_it()}
          >
            Save
          </Button>
        </p>
      </Form.Group>
    </Form>
  );
}

export function Group() {
  let { group_id } = useParams();
  let { is_loading, is_failure, response, reload } = useApi.getGroup({
    group_id,
  });
  let [is_deleted, set_deleted] = useState(false);
  if (is_deleted) {
    return <Redirect to="/groups" />;
  }
  if (is_loading) {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <Spinner animation="border" />
          </Col>
        </Row>
      </Container>
    );
  }
  if (is_failure) {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <Alert variant="danger">Failed to load group.</Alert>
          </Col>
        </Row>
      </Container>
    );
  }
  let { group } = response;
  let { group_name, devices, created_at } = group;
  return (
    <Container className="mt-5">
      <Row>
        <Col xs={3}>
          <Card>
            <Card.Body>
              <Card.Title>{group_name}</Card.Title>
              <Button
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this group?\n" +
                        "Note: devices won't be deleted"
                    )
                  ) {
                    Api.deleteGroup({ group_id }).then(() => set_deleted(true));
                  }
                }}
              >
                <MdDelete />
              </Button>
              <Button
                className="ml-2"
                onClick={() => Api.broadcastGroup({ group_id })}
              >
                Broadcast <MdSend />
              </Button>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                Created {moment(created_at).fromNow()}
              </small>
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={9}>
          <Card>
            <ListGroup variant="flush">
              {devices.length ? null : (
                <ListGroup.Item>No devices belong to the group.</ListGroup.Item>
              )}
              {devices.map(({ device_id, group_device_color }) => (
                <GroupDeviceItem
                  key={device_id}
                  group_id={group_id}
                  device_id={device_id}
                  group_device_color={group_device_color}
                  onDeviceRemoved={reload}
                  onColorChange={reload}
                />
              ))}
              <ListGroup.Item key="add-device">
                <AddDeviceDropdown
                  device_ids={_.map(devices, "device_id")}
                  onAdd={(device_id) =>
                    Api.addGroupDevice({
                      group_id,
                      device_id,
                      // TODO: consider making color selection part of adding?
                      group_device_color: 0xffffff, // default upon creation
                    }).then(reload)
                  }
                />
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

function GroupDeviceItem({
  group_id,
  device_id,
  group_device_color,
  onColorChange,
  onDeviceRemoved,
}) {
  let { is_loading, is_failure, response } = useApi.getDevice({
    device_id,
  });
  if (is_loading) {
    return (
      <ListGroup.Item>
        <Spinner animation="border" />
      </ListGroup.Item>
    );
  }
  if (is_failure) {
    return (
      <ListGroup.Item>
        <Button
          size="sm"
          className="mr-2"
          variant="secondary"
          onClick={() =>
            Api.removeGroupDevice({
              group_id,
              device_id,
            }).then(onDeviceRemoved)
          }
        >
          <MdRemove />
        </Button>
        <Button
          size="sm"
          className="mr-2"
          variant="secondary"
          href={`/device/${device_id}`}
        >
          <span className="text-danger">Failed to load device info.</span>
        </Button>
      </ListGroup.Item>
    );
  }
  let { hardware_identifier, tag_texts } = response.device;
  return (
    <ListGroup.Item>
      <Button
        size="sm"
        className="mr-2"
        variant="secondary"
        onClick={() =>
          Api.removeGroupDevice({
            group_id,
            device_id,
          }).then(onDeviceRemoved)
        }
      >
        <MdRemove />
      </Button>
      <Button
        size="sm"
        className="mr-2"
        variant="secondary"
        href={`/device/${device_id}`}
      >
        {hardware_identifier}
        <span className="ml-2">
          {tag_texts.map((tag_text) => (
            <Badge key={tag_text} className="mr-2" variant="info">
              {tag_text}
            </Badge>
          ))}
        </span>
      </Button>
      <ColorPicker
        color={{
          red: (group_device_color & 0xff0000) >> 16,
          green: (group_device_color & 0xff00) >> 8,
          blue: group_device_color & 0xff,
        }}
        onChange={({ red, green, blue }) =>
          Api.addGroupDevice({
            // "upsert"
            group_id,
            device_id,
            group_device_color: (red << 16) + (green << 8) + blue,
          }).then(onColorChange)
        }
      />
      <DeviceStatusIndicator device_id={device_id} />
    </ListGroup.Item>
  );
}

// online = received any event in the last 90s
const online_time_horizon_ms = 90 * 1000;
// active = last 10m  TODO: make group-configurable
const active_time_horizon_ms = 10 * 60 * 1000;
function DeviceStatusIndicator({ device_id }) {
  // We approximate "online" (any event in the last 90s)
  // and "active" (an activation event in the last 30 events)
  let es = useApi.listDeviceEvents({ device_id, page_size: 30 });
  if (!es.response) {
    return (
      <span className="ml-2">
        <Spinner size="sm" animation="border" />
      </span>
    );
  }
  let is_online =
    es.response.events.length > 0 &&
    Date.now() - es.response.events[0].created_at < online_time_horizon_ms;
  let last_activated = _.find(
    es.response.events,
    (event) => event.event === topics.Event.DEVICE_ACTIVATED
  );
  let last_activated_at = last_activated ? last_activated.created_at : 0;
  let is_active =
    last_activated && Date.now() - last_activated_at < active_time_horizon_ms;
  return (
    <h4 className="mb-0 float-right">
      <Badge key="active" variant={is_active ? "light" : "secondary"}>
        {is_active
          ? `active ${moment(last_activated_at).fromNow()}`
          : `inactive`}
      </Badge>
      <Badge key="connection" className="ml-2" variant="secondary">
        {is_online ? (
          <span className="text-success">
            <MdSignalWifi4Bar /> Online
          </span>
        ) : (
          <span className="text-danger">
            <MdSignalWifiOff /> Offline
          </span>
        )}
      </Badge>
    </h4>
  );
}

function AddDeviceDropdown({ device_ids, onAdd }) {
  let { is_loading, is_failure, response } = useApi.listDevices({
    page_size: 100,
    tag_texts: [],
  });
  let [filter, set_filter] = useState("");
  return (
    <Dropdown className="mr-2 mb-2" onSelect={onAdd}>
      <Dropdown.Toggle size="sm" variant="secondary">
        <small>Add device</small>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>
          <InputGroup size="sm" className="w-auto">
            <Form.Control
              autoFocus
              placeholder=""
              onChange={(e) => set_filter(e.target.value)}
              value={filter}
            />
          </InputGroup>
        </Dropdown.Header>
        {(is_loading || is_failure ? [] : response.devices)
          // exclude those already added to the group
          .filter(
            ({ device_id }) => (device_ids || []).indexOf(device_id) === -1
          )
          // exclude those that don't match the filter (if specified)
          .filter(({ device_id, hardware_identifier, tag_texts }) => {
            if (!filter) {
              return true;
            }
            let matchables = [device_id, hardware_identifier].concat(
              tag_texts || []
            );
            return _.some(matchables, (s) => s.indexOf(filter) !== -1);
          })
          .map(({ device_id, hardware_identifier, tag_texts }) => (
            <Dropdown.Item key={device_id} eventKey={device_id}>
              {hardware_identifier}
              <span className="ml-2">
                {tag_texts.map((tag_text) => (
                  <Badge key={tag_text} className="mr-2" variant="info">
                    {tag_text}
                  </Badge>
                ))}
              </span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function ColorPicker({ color, onChange }) {
  let [picking, set_picking] = useState(false);
  return (
    <span className="mt-0 mb-0">
      <ButtonGroup size="sm">
        <Button variant="secondary" onClick={() => set_picking(!picking)}>
          <div
            style={{
              width: 36,
              height: 14,
              background: `rgba(${color.red}, ${color.green}, ${color.blue}, 1)`,
            }}
          />
        </Button>
        <Button
          onClick={() => {
            let [red, green, blue] = random_color({
              luminosity: "light",
              format: "rgbArray",
            });
            onChange({ red, green, blue });
          }}
          variant="secondary"
        >
          <FaRandom />
        </Button>
      </ButtonGroup>
      {!picking ? null : (
        <SketchPicker
          className="mt-2"
          disableAlpha
          width={193}
          onChange={({ rgb: { r, g, b } }) =>
            onChange({
              red: r,
              green: g,
              blue: b,
            })
          }
          color={{
            r: color.red || 0,
            g: color.green || 0,
            b: color.blue || 0,
          }}
          presetColors={["#f00", "#ff0", "#0f0", "#0ff", "#00f", "#f0f"]}
        />
      )}
    </span>
  );
}
