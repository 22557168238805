import firebase, { firebase_app, useAuth } from "./firebase";
import { Button, Jumbotron, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import StyledFirebaseAuth from "react-firebaseui/FirebaseAuth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Device, Devices } from "./Device";
import { Group, Groups } from "./Group";

function App() {
  const { is_signed_in, is_allowed } = useAuth();
  if (is_signed_in === null) {
    // To avoid showing the login prompt for a moment on load
    // when we're already signed in, this just shows a black screen
    // until firebase confirms explicitly whether we're already signed in.
    //  i.e. while is_signed_in is `null` and not yet `true` or `false`
    return <div />;
  }
  if (is_signed_in === false) {
    // explicitly false means firebase confirmed we're not signed in
    return <LoginPrompt />;
  }
  if (is_allowed === null) {
    // We're signed in (authenticated)
    // but we're waiting to check if we're allowed (authorization).
    return <div />;
  }
  if (is_allowed === false) {
    return <NotAllowed />;
  }
  // we're signed in and allowed, so take them to the page
  return (
    <Router>
      <AdminNav />
      <Switch>
        <Route path="/devices" component={Devices} />
        <Route path="/device/:device_id" component={Device} />
        <Route path="/groups" component={Groups} />
        <Route path="/group/:group_id" component={Group} />
      </Switch>
    </Router>
  );
}

function LoginPrompt() {
  return (
    <Jumbotron>
      <h1>Admin Glendon</h1>
      <p>TK: something scary about how someone is watching your every click.</p>
      <div>
        <StyledFirebaseAuth
          className="justify-content-start"
          uiConfig={{
            signInFlow: "popup", // not redirect
            signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
            callbacks: {
              signInSuccessWithAuthResult: () => false, // prevent redirect after login
            },
          }}
          firebaseAuth={firebase_app.auth()}
        />
      </div>
    </Jumbotron>
  );
}

function NotAllowed() {
  return (
    <Jumbotron>
      <h1>Admin Glendon</h1>
      <p>Sorry, it looks like you don't have access yet.</p>
      <p>
        {firebase_app.auth().currentUser.displayName}{" "}
        <Button variant="link" onClick={() => firebase.auth().signOut()}>
          sign out
        </Button>
      </p>
    </Jumbotron>
  );
}

function AdminNav() {
  let { pathname } = useLocation();
  return (
    <Navbar bg="dark" variant="dark">
      <LinkContainer to="/">
        <NavbarBrand>Admin</NavbarBrand>
      </LinkContainer>
      <Nav>
        <LinkContainer to="/devices">
          <Nav.Link active={pathname.startsWith("/device")}>Devices</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/groups">
          <Nav.Link active={pathname.startsWith("/group")}>Groups</Nav.Link>
        </LinkContainer>
      </Nav>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          {firebase_app.auth().currentUser.displayName}{" "}
          <Button variant="link" onClick={() => firebase_app.auth().signOut()}>
            sign out
          </Button>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default App;
